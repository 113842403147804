/*TP-3328*/
#my_editor {order:2;}

#profile-controls{order:1;}

.margin-photo-editor {
    margin-right: 10%
}

.scale-div{
    input {
        cursor: pointer;
    }
}

.color-icon {
    color:black;
    z-index: 9999;
}

.color-grey {
    color: grey;
}

.back-icon {
    
    font-size: 30px;    
    cursor: pointer;
}

/*TP-3497*/
.remove-icon {
    font-size: 30px;    
    cursor: pointer;
}

.upload-icon {
    font-size: 30px;
    cursor: pointer;
}

.save-icon {
    font-size: 30px;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .profile-controls {
        height:35px;
        margin-top: -20% !important;
        margin-left: 18% !important;
    }
    /*TP-3497*/
    .position-dropdown-profile {
        /* margin-top: 21%; */
        margin-left: 55%;//TP-6147 pic placeheolder position fix
        border-radius: 10px;
    }
    /*TP-3672*/
    .my-profile-pic{
        height: 200px;
        width: 200px;
        border-radius: 50%;
        margin-right: 1.5%;
        cursor: pointer;
        object-fit: cover;
        /* float: right; */
    }
    .edit-icon {
        margin-left: -55px;
        margin-top: 168px;
        font-size: 30px;
        cursor: pointer;
    }
}

@media screen and (min-width: 1025px) {
    .profile-controls {
        height:35px;
        margin-top: -20% !important;
        margin-left: 10% !important; 
        padding-left: 10% !important; /*TP-3497*/
    }
    /*TP-3497*/
    .position-dropdown-profile {
        /* margin-top: 21%; */
        margin-left: 55%;//TP-6147 pic placeheolder position fix
        border-radius: 10px;
    }
    /*TP-3672*/
    .my-profile-pic{
        height: 200px;
        width: 200px;
        border-radius: 50%;
        margin-right: 1.5%;
        cursor: pointer;
        object-fit: cover;
        /* float: right; */
    }
    .edit-icon {
        margin-left: -55px;
        margin-top: 168px;
        font-size: 30px;
        cursor: pointer;
    }
}

@media screen and (max-width: 992px) {
    .profile-controls {
        height:35px;
         margin-top: -20% !important;
        margin-left: 10% !important; 
        padding-left: 10% !important;
    }
    /*TP-3497*/
    .position-dropdown-profile {
        margin-top: -14%; //TP-3672
        margin-left: 61%;
        border-radius: 10px;
    }
    /*TP-3672*/
    .my-profile-pic{
        height: 150px;
        width: 150px;
        border-radius: 50%;
        margin-right: 1.5%;
        cursor: pointer;
        object-fit: cover;
        /* float: right; */
    }
    .edit-icon {
        margin-left: -55px;
        margin-top: 140px;
        font-size: 30px;
        cursor: pointer;
    }
}

/*TP-3199*/
.photo-modal-body {
    canvas {
        border-radius: 10%;
        background-color: white;
    }
}

.text-black{
    color: black;
}

.width-profile {
    width:85%;//TP-6082
}

input .disable-class {
    color: grey;
}

.center {
    margin-right: auto !important;
    margin-left: auto !important;
}

/*TP-4664*/
.nocameracls {
    color: red !important;
}

.profile-otp {
    background: transparent;
    border: 2px solid #fff;
    border-radius: 6px;
    color: #fff;
    font-size: 22px;
    outline: none;
    padding: 3px;//TP-6277,6276
    max-width: 40px;
  }
  