
.confirm-modal {
    .__modal-body {
        h4 {
            margin-bottom: 10px;
            margin-top: 0;
        }
    }
    .__modal-footer {
        .btn-cancel {
            margin-right: 10px;
        }
    }
}