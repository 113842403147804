@import '../../styles/shared';

.signin-input-field {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 6px;
  color: #fff;
  font-size: 22px;
  margin-top: 9%;
  outline: none;
  padding: 6px 12px 6px 14%;
}

.signin-input-font-md {
  width: 400px;
  height: 48px;
}
.signin-input-font-lg {
  width: 500px;
  height: 65px;
}

.usernameIcon {
  background: url(/username_icon.png) no-repeat scroll;
  background-position: 14px 50%;
  background-size: 22px;
}
.password-icon {
  background: url(/password_lock_icon.png) no-repeat scroll;
  background-position: 14px 50%;
  background-size: 20px;
}
.mobileIcon {
  background: url(/mobile_icon.png) no-repeat scroll;
  background-position: 14px 50%;
  background-size: 22px;
}

.signin-link-font-lg {
  font-size: x-large;
}
.signin-go-font-lg{
  font-size: 42px;
}
.signin-go-font-md {
  font-size: 36px;
}
.signin-go-text {
  color: #fff;
  align-self: center;
}
.signin-check-icon-font-md {
  padding-top: 5px;
}

.signin-check-icon-font-lg {
  padding-top: 10px;
}

.signin-check-icon[disabled] {
  filter: grayscale(1);
  opacity: 0.5;
}

.signin-check-icon:focus {
  outline: none !important;
}

.signin-check-icon:hover {
  opacity: 0.75;
}

.btn-green {
  @include button-mixin();
}
.placeholder {
  color: #fff;
  font-family: TitilliumWebRegular !important;
}
.signin-input-field::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  @extend .placeholder;
}

.signin-input-field:-ms-input-placeholder { 
   /* Internet Explorer 10-11 */
 @extend .placeholder;
}

.signin-input-field::-ms-input-placeholder { 
   /* Microsoft Edge */
 @extend .placeholder;
}

.signin-validation-msg {
  @include validation-message($right: 0,$font-size:1rem)
}

/*TP-1728*/
#ShowError{
  color: #f7c1c1;
  font-weight: 500;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #FF0000;
  font-family: 'Titillium Web';
  font-size: larger;
}

.forget-password-link{
  a {
    color: white;
  }
}

.forget-password-link a:hover {
    color: white;
}

.version-badge {
  color: #504fce;
}

.white-text {
  color: #fff;
}

.margin-ns {
  margin-top: 20%;
}
.text25 {
  font-size: 25px;
}

.text30 {
  font-size: 30px;
}

.right {
  float: right;
  /* margin-right: 5%; */
}

.logo-right {
  margin-right: 5%;
}

.logo-left {
  margin-left: 12%;
}

.text-left {
  margin-left: 12%;
}

.center-text {
  text-align: center;
}

.logo-sv {
  border-radius: 30%;
}

.log-me-color {
  background-color: transparent !important;    
}

.log-me-min-width {
  width: 700px;
}

.logo-button {
  background: linear-gradient(#B0B0B0 1%, #ffff 50%, #F0F0F0);
  /* background-color: white; */
}

.logo-button:hover {
  /* background: linear-gradient(#808080 1%, #ffff 50%, #808080) !important;  */
  /* background: #ffff !important; */
  background: white !important;  
  box-shadow: 2px 2px 7px 7px white;
  border-color: #fff !important;
}

.mt-5 {
  margin-top: 5% !important;
}

.mt-2 {
  margin-top: 2% !important;
}

.row-min-height-md {
  min-height: 100px;
}

.row-min-height-lg {
  min-height: 200px;
}

.signin-dropdown {
  margin-top: 5% !important;
}

/*TP-1728*/
.signin-dropdown-lg {
  margin-top: 2% !important;
}

/*TP-1728*/
.error-row {
  margin-top: 38% !important;
}

.log-me-center-dialog {
  top: 6% !important;    
    /* height: 630px; */
    border-radius: 25px;
    margin-top: 1%;
    /* margin-left: 20%;
    margin-right: 20%; */
    padding: 1% 2% 4% 1%;
}

.wrap-error {
  white-space: pre-line;
}

.highlight-text {
  // text-shadow: 2px 2px #949494;
  font-weight: bold;
  // text-decoration: underline;
  border-bottom: 2px solid #fff;
}

.otp {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 6px;
  color: #fff;
  font-size: 22px;
  margin-top: 9%;
  outline: none;
  padding: 5px;
  max-width: 50px;
}
