/* titillium-web-regular */
@font-face {
  font-family: 'Titillium Web';
  src: url(/static/media/TitilliumWeb-Regular.ed1d014b.ttf) format("truetype"); }

@font-face {
  font-family: 'FontAwesome';
  src: url(/static/media/fontawesome-webfont.b06871f2.ttf); }

body {
  margin: 0;
  padding: 0;
  background-size: cover; }

.default-blue {
  color: #485890; }

body {
  margin: 0;
  font-family: 'Titillium Web' !important; }

#header_bar {
  font-family: 'Titillium Web' !important;
  height: 98px;
  width: 100%;
  background-color: #293840;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: fixed;
  display: -ms-flexbox;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3; }

#header_bar .left-block {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  float: left;
  width: 269px;
  min-width: 269px;
  height: 100%;
  text-align: center;
  border-right: 1px solid #455b66; }

[header-logo] {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%; }

[header-logo="brand_logo"] {
  background-image: url(/white_logo_transparent_background.png);
  width: 164.2px;
  height: 100%; }

#header_bar .center-block {
  float: left;
  flex-grow: 1;
  height: 100%;
  display: flex;
  padding-left: 30px;
  color: white;
  font-size: 18px; }

#header_bar .landing-center-block {
  flex-grow: 1;
  height: 100%;
  display: flex;
  padding-left: 280px;
  color: white;
  font-size: 32px; }

.landing-group-name {
  font-size: 30px;
  word-break: break-all;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 290px;
  color: white; }

#header_bar .center-block .group-info {
  font-size: 13px;
  font-weight: 600;
  line-height: 0.5;
  letter-spacing: 1.1px;
  color: #7a8b93;
  min-width: 60px; }

#header_bar .center-block .group-info .group-name {
  font-size: 22px;
  text-overflow: ellipsis;
  height: 26px;
  overflow: hidden;
  white-space: nowrap;
  line-height: 22px;
  margin-bottom: 8px; }

.group-name {
  font-size: 16px;
  word-break: break-all;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 290px;
  color: white; }

[header-logo="joinees"] {
  width: 25px;
  height: 26px;
  margin-right: -4px;
  margin-bottom: -1px;
  display: block; }

#actionBtnDiv {
  font-size: 14px;
  padding-left: 5px;
  display: flex; }

#joineesCount {
  font-size: 14px;
  padding-left: 12px;
  display: flex;
  color: white;
  text-align: center; }

#actionLabel {
  font-size: 10px;
  padding-left: 5px;
  display: flex; }

#header_bar .right-block {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  right: 0; }

#header_bar .right-block div {
  float: right; }

#header_bar .muteAll span {
  margin-left: 12px;
  margin-right: 12px;
  vertical-align: top;
  line-height: 36px;
  font-size: 12px;
  color: #c0c0c0; }

.btn-padding, .btn-no-underline {
  padding: 0 30px; }

#header_bar .signout-container {
  width: 114px;
  height: 98px;
  border-left: 1px solid #455b66;
  text-align: center;
  display: flex;
  right: 0;
  -moz-flex-direction: row;
  flex-direction: row;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center; }

#header_bar .signout-container #signout-icon {
  height: auto;
  width: auto;
  float: none;
  color: white;
  cursor: pointer; }

.topnav-demo {
  opacity: 0 !important;
  height: 50px !important; }

.topnav {
  font-family: 'Titillium Web';
  position: relative;
  overflow: hidden;
  background-color: #333;
  opacity: 0.7;
  color: #fff; }

.topnav a {
  font-family: 'Titillium Web';
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none; }

.topnav-centered a {
  font-family: 'Titillium Web';
  float: none;
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.topnav-right a {
  font-family: 'Titillium Web';
  font-size: 18px;
  color: white;
  float: right;
  cursor: pointer; }

/* Style buttons */
.btn {
  background-color: DodgerBlue;
  /* Blue background */
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 12px 16px;
  /* Some padding */
  font-size: 16px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  opacity: 0.9;
  cursor: pointer; }

/* Darker background on mouse-over */
.btn:hover {
  opacity: 0.5; }

.navbar-gradient {
  background-color: #7452cb;
  /* For browsers that do not support gradients */
  background-image: linear-gradient(to left, #9052da 40%, #414fcb 60%); }

.nav-link {
  outline: 0;
  border: none;
  outline-style: none; }
  .nav-link i {
    font-size: 42px !important;
    color: white; }

.small-nav-link i {
  font-size: 27px !important;
  color: white; }

.sm-nav-link {
  outline: 0;
  border: none;
  outline-style: none; }
  .sm-nav-link i {
    font-size: 35px !important;
    margin: 12% 20% !important;
    color: white; }

.md-nav-link {
  outline: 0;
  border: none;
  outline-style: none; }
  .md-nav-link i {
    font-size: 25px !important;
    margin: 8% 20% !important;
    color: white; }

.xs-nav-link {
  outline: 0;
  border: none;
  outline-style: none; }
  .xs-nav-link i {
    font-size: 20px !important;
    margin: 4% 20% !important;
    color: white; }

.d-none {
  display: none; }

.d-blk {
  display: blo; }

.font-awesome-red {
  color: red !important; }

.font-awesome-blue {
  color: #400088 !important; }

.font-awesome-green {
  color: #23c97d !important; }

.signout-no-decoration {
  font-size: 25px;
  color: #fff !important; }

@media screen and (max-width: 1024px) {
  .navbar-text {
    width: 650px; }
  nav {
    height: inherit; } }

@media screen and (min-width: 1025px) {
  .navbar-text {
    width: 92%;
    /*TP-3497*/ }
  nav {
    height: inherit; } }

@media screen and (max-width: 992px) {
  #header_bar {
    height: 40px !important; }
  .navbar-left {
    text-align: left !important; }
  .navbar-right {
    text-align: right !important; }
  .nav-item {
    text-align: left !important; }
  .navbar-text {
    text-align: left !important; }
  .participant-count {
    left: 40px  !important; }
  .selectBox {
    width: 115px; }
  .signout-no-decoration {
    font-size: 20px; }
  nav {
    height: inherit; }
  .row-height {
    height: 30px; } }

.participant-count {
  position: absolute;
  left: 57%; }

.chat-participant-count {
  position: absolute;
  left: 60.5%; }

.hr-separator {
  border-left: 1px solid #dbe2e6;
  height: 97px;
  margin: 0; }

.selectBox {
  border-radius: 5px;
  border: 1px solid #AAAAAA;
  background: rgba(36, 32, 59, 0.3);
  color: #fff; }

.selectBox option {
  color: black; }

.no-cursor-pointer {
  pointer-events: none; }

.faded-image {
  opacity: 0.5; }

.cursor-pointer {
  cursor: pointer; }

/*.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}*/
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: grey;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1; }

.tooltip:hover .tooltiptext {
  visibility: visible; }

/* webrtc css*/
.member-count {
  left: 95%;
  font-family: 'Titillium Web' !important;
  /* top: 14%; */ }

.chat-count {
  left: 95%;
  font-family: 'Titillium Web' !important;
  /* top: 14%; */ }

.nav-link .rtcIcon {
  font-size: 27px !important; }

/* webrtc side bar gradient */
.sidebar-gradient {
  background-color: #7452cb;
  /* For browsers that do not support gradients */
  background-image: linear-gradient(#9052da 40%, #414fcb 60%); }

.high-zindex {
  z-index: 1060; }

.left-Icons {
  margin-left: 10px; }

.sideBar-icons {
  opacity: 1; }

.sideBar-icons:hover {
  opacity: 0.6; }

.remove-dots {
  outline: none; }

.cursor-pointer {
  cursor: pointer !important; }

.icon-disabled {
  color: #b77070 !important; }

.df-cursor {
  cursor: default !important; }

#joineesTooltip {
  z-index: 2 !important; }

.padding-left-7 {
  padding-left: 7px; }

.padding-left-5 {
  padding-left: 5px; }

/*TP-3199*/
.profile-pic {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-right: 1.5%;
  cursor: pointer;
  object-fit: cover;
  /* float: right; */ }

.profile-pic-sm {
  height: 35px;
  width: 35px;
  margin-top: -5px;
  border-radius: 50%;
  margin-right: 1.5%;
  cursor: pointer;
  object-fit: cover; }

.user-name {
  margin-right: 3%;
  width: 290px;
  float: right !important; }

.photo-modal-body {
  /* margin-left: 12%; */ }

.photo-modal-footer {
  margin-top: 5%; }

.left-button {
  margin-left: 20%; }

.right-button {
  margin-left: 15%; }

.scale-div input {
  margin-left: 10px; }

.margin-top-15 {
  margin-top: 5px; }

.name-class {
  padding-left: 10px; }

.default-blue {
  color: #485890; }

/*************MIXINS*************/
/**********************************/
.signup-popup-wrapper {
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 10px 0; }

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #485890;
  font-size: 24;
  text-align: left;
  height: 565px;
  max-height: 565px; }

.signup-input-field, .react-datepicker__input-container input, .react-datepicker-wrapper input {
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  padding: 10px; }

.signup-input-field:focus, .react-datepicker__input-container input:focus, .react-datepicker-wrapper input:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE; }

.flex-center, .flex-center-wrap {
  display: flex;
  justify-content: center; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center-wrap {
  flex-wrap: wrap; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-align-center {
  display: flex;
  align-items: center; }

.hidden {
  display: none; }

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform; }

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #777; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #777; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #777; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.relative {
  position: relative; }

.pointer {
  cursor: pointer; }

/*****Validation message styles******/
.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important; }
  .loader div, .loader span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff; }

.loader-text {
  top: 53% !important;
  position: absolute;
  color: #fff; }

.left-loader-multi-word {
  right: 40% !important; }

.left-loader-one-word {
  right: 45% !important; }

.left-loader-two-words {
  right: 43% !important; }

ul {
  list-style: none;
  list-style-type: none; }

label {
  display: initial !important; }

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important; }

.opac-0-7 {
  opacity: 0.7 !important; }

.opac-1 {
  opacity: 1; }

.modal-header {
  font-size: 1.4rem !important; }

.video-container video {
  width: inherit !important; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-5px); }
  60% {
    transform: translateY(0px); } }

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom; }

.animate {
  animation-duration: 2s;
  animation-fill-mode: both; }

.btn-g {
  background: #23c97d !important;
  border-color: #23c97d !important; }

.btn-r {
  background: #FF0000 !important;
  border-color: #FF0000 !important; }

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

.__btn {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #23c97d;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }
  .__btn .fa-spinner {
    margin-right: 5px; }

.checkbox-span {
  display: inline-block;
  width: 18px;
  height: 16px;
  margin-right: 10px;
  background-image: linear-gradient(white, white);
  margin-bottom: 1px;
  border: 1px solid #999;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  -ms-border-radius: 2px;
  background: #fff;
  background-image: linear-gradient(white, white);
  vertical-align: middle;
  cursor: pointer; }

.checkbox-span.checked::before {
  display: block;
  line-height: 1.2;
  margin-top: 0px;
  text-align: center;
  content: "\F00C";
  font: normal normal normal 13px/1 FontAwesome; }

.checkbox-input {
  width: 2em;
  margin: 0;
  padding: 0;
  font-size: 1em;
  display: none; }

.default-blue {
  color: #485890; }

/*************MIXINS*************/
/**********************************/
.signup-popup-wrapper {
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 10px 0; }

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #485890;
  font-size: 24;
  text-align: left;
  height: 565px;
  max-height: 565px; }

.signup-input-field, .react-datepicker__input-container input, .react-datepicker-wrapper input {
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  padding: 10px; }

.signup-input-field:focus, .react-datepicker__input-container input:focus, .react-datepicker-wrapper input:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE; }

.flex-center, .flex-center-wrap {
  display: flex;
  justify-content: center; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center-wrap {
  flex-wrap: wrap; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-align-center {
  display: flex;
  align-items: center; }

.hidden {
  display: none; }

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform; }

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #777; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #777; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #777; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.relative {
  position: relative; }

.pointer {
  cursor: pointer; }

/*****Validation message styles******/
.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important; }
  .loader div, .loader span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff; }

.loader-text {
  top: 53% !important;
  position: absolute;
  color: #fff; }

.left-loader-multi-word {
  right: 40% !important; }

.left-loader-one-word {
  right: 45% !important; }

.left-loader-two-words {
  right: 43% !important; }

ul {
  list-style: none;
  list-style-type: none; }

label {
  display: initial !important; }

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important; }

.opac-0-7 {
  opacity: 0.7 !important; }

.opac-1 {
  opacity: 1; }

.modal-header {
  font-size: 1.4rem !important; }

.video-container video {
  width: inherit !important; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-5px); }
  60% {
    transform: translateY(0px); } }

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom; }

.animate {
  animation-duration: 2s;
  animation-fill-mode: both; }

.btn-g {
  background: #23c97d !important;
  border-color: #23c97d !important; }

.btn-r {
  background: #FF0000 !important;
  border-color: #FF0000 !important; }

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

.signin-input-field {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 6px;
  color: #fff;
  font-size: 22px;
  margin-top: 9%;
  outline: none;
  padding: 6px 12px 6px 14%; }

.signin-input-font-md {
  width: 400px;
  height: 48px; }

.signin-input-font-lg {
  width: 500px;
  height: 65px; }

.usernameIcon {
  background: url(/username_icon.png) no-repeat scroll;
  background-position: 14px 50%;
  background-size: 22px; }

.password-icon {
  background: url(/password_lock_icon.png) no-repeat scroll;
  background-position: 14px 50%;
  background-size: 20px; }

.mobileIcon {
  background: url(/mobile_icon.png) no-repeat scroll;
  background-position: 14px 50%;
  background-size: 22px; }

.signin-link-font-lg {
  font-size: x-large; }

.signin-go-font-lg {
  font-size: 42px; }

.signin-go-font-md {
  font-size: 36px; }

.signin-go-text {
  color: #fff;
  align-self: center; }

.signin-check-icon-font-md {
  padding-top: 5px; }

.signin-check-icon-font-lg {
  padding-top: 10px; }

.signin-check-icon[disabled] {
  filter: grayscale(1);
  opacity: 0.5; }

.signin-check-icon:focus {
  outline: none !important; }

.signin-check-icon:hover {
  opacity: 0.75; }

.btn-green {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #23c97d;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }

.signin-input-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */ }

.signin-input-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */ }

.signin-input-field::-ms-input-placeholder {
  /* Microsoft Edge */ }

.signin-validation-msg {
  color: #FF0000;
  margin-top: 3px;
  position: absolute;
  font-size: 1rem;
  right: 0;
  left: 5px; }

/*TP-1728*/
#ShowError {
  color: #f7c1c1;
  font-weight: 500;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #FF0000;
  font-family: 'Titillium Web';
  font-size: larger; }

.forget-password-link a {
  color: white; }

.forget-password-link a:hover {
  color: white; }

.version-badge {
  color: #504fce; }

.white-text {
  color: #fff; }

.margin-ns {
  margin-top: 20%; }

.text25 {
  font-size: 25px; }

.text30 {
  font-size: 30px; }

.right {
  float: right;
  /* margin-right: 5%; */ }

.logo-right {
  margin-right: 5%; }

.logo-left {
  margin-left: 12%; }

.text-left {
  margin-left: 12%; }

.center-text {
  text-align: center; }

.logo-sv {
  border-radius: 30%; }

.log-me-color {
  background-color: transparent !important; }

.log-me-min-width {
  width: 700px; }

.logo-button {
  background: linear-gradient(#B0B0B0 1%, #ffff 50%, #F0F0F0);
  /* background-color: white; */ }

.logo-button:hover {
  /* background: linear-gradient(#808080 1%, #ffff 50%, #808080) !important;  */
  /* background: #ffff !important; */
  background: white !important;
  box-shadow: 2px 2px 7px 7px white;
  border-color: #fff !important; }

.mt-5 {
  margin-top: 5% !important; }

.mt-2 {
  margin-top: 2% !important; }

.row-min-height-md {
  min-height: 100px; }

.row-min-height-lg {
  min-height: 200px; }

.signin-dropdown {
  margin-top: 5% !important; }

/*TP-1728*/
.signin-dropdown-lg {
  margin-top: 2% !important; }

/*TP-1728*/
.error-row {
  margin-top: 38% !important; }

.log-me-center-dialog {
  top: 6% !important;
  /* height: 630px; */
  border-radius: 25px;
  margin-top: 1%;
  /* margin-left: 20%;
    margin-right: 20%; */
  padding: 1% 2% 4% 1%; }

.wrap-error {
  white-space: pre-line; }

.highlight-text {
  font-weight: bold;
  border-bottom: 2px solid #fff; }

.otp {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 6px;
  color: #fff;
  font-size: 22px;
  margin-top: 9%;
  outline: none;
  padding: 5px;
  max-width: 50px; }

.default-blue {
  color: #485890; }

/*************MIXINS*************/
/**********************************/
.signup-popup-wrapper {
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 10px 0; }

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #485890;
  font-size: 24;
  text-align: left;
  height: 565px;
  max-height: 565px; }

.signup-input-field, .react-datepicker__input-container input, .react-datepicker-wrapper input {
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  padding: 10px; }

.signup-input-field:focus, .react-datepicker__input-container input:focus, .react-datepicker-wrapper input:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE; }

.flex-center, .flex-center-wrap {
  display: flex;
  justify-content: center; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center-wrap {
  flex-wrap: wrap; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-align-center {
  display: flex;
  align-items: center; }

.hidden {
  display: none; }

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform; }

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #777; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #777; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #777; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.relative {
  position: relative; }

.pointer {
  cursor: pointer; }

/*****Validation message styles******/
.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important; }
  .loader div, .loader span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff; }

.loader-text {
  top: 53% !important;
  position: absolute;
  color: #fff; }

.left-loader-multi-word {
  right: 40% !important; }

.left-loader-one-word {
  right: 45% !important; }

.left-loader-two-words {
  right: 43% !important; }

ul {
  list-style: none;
  list-style-type: none; }

label {
  display: initial !important; }

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important; }

.opac-0-7 {
  opacity: 0.7 !important; }

.opac-1 {
  opacity: 1; }

.modal-header {
  font-size: 1.4rem !important; }

.video-container video {
  width: inherit !important; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-5px); }
  60% {
    transform: translateY(0px); } }

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom; }

.animate {
  animation-duration: 2s;
  animation-fill-mode: both; }

.btn-g {
  background: #23c97d !important;
  border-color: #23c97d !important; }

.btn-r {
  background: #FF0000 !important;
  border-color: #FF0000 !important; }

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

.default-blue {
  color: #485890; }

.btn-register {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #23c97d;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }

.__modal-body {
  color: #485890; }
  .__modal-body .input-wrapper {
    margin-bottom: 12px; }

.register-form-validation-msg {
  color: #FF0000;
  margin-top: 3px;
  position: absolute;
  font-size: 12px;
  right: none;
  left: 5px; }

.default-blue {
  color: #485890; }

/*************MIXINS*************/
/**********************************/
.signup-popup-wrapper {
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 10px 0; }

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #485890;
  font-size: 24;
  text-align: left;
  height: 565px;
  max-height: 565px; }

.signup-input-field, .react-datepicker__input-container input, .react-datepicker-wrapper input {
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  padding: 10px; }

.signup-input-field:focus, .react-datepicker__input-container input:focus, .react-datepicker-wrapper input:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE; }

.flex-center, .flex-center-wrap {
  display: flex;
  justify-content: center; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center-wrap {
  flex-wrap: wrap; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-align-center {
  display: flex;
  align-items: center; }

.hidden {
  display: none; }

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform; }

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #777; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #777; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #777; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.relative {
  position: relative; }

.pointer {
  cursor: pointer; }

/*****Validation message styles******/
.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important; }
  .loader div, .loader span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff; }

.loader-text {
  top: 53% !important;
  position: absolute;
  color: #fff; }

.left-loader-multi-word {
  right: 40% !important; }

.left-loader-one-word {
  right: 45% !important; }

.left-loader-two-words {
  right: 43% !important; }

ul {
  list-style: none;
  list-style-type: none; }

label {
  display: initial !important; }

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important; }

.opac-0-7 {
  opacity: 0.7 !important; }

.opac-1 {
  opacity: 1; }

.modal-header {
  font-size: 1.4rem !important; }

.video-container video {
  width: inherit !important; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-5px); }
  60% {
    transform: translateY(0px); } }

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom; }

.animate {
  animation-duration: 2s;
  animation-fill-mode: both; }

.btn-g {
  background: #23c97d !important;
  border-color: #23c97d !important; }

.btn-r {
  background: #FF0000 !important;
  border-color: #FF0000 !important; }

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

.btn-green-signup {
  display: block;
  height: 40px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #23c97d;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px;
  text-transform: uppercase;
  text-decoration: none; }

.btn-green:hover {
  opacity: 1; }

.signup-validation-message {
  color: #FF0000;
  margin-top: 3px;
  position: absolute;
  font-size: 12px;
  right: none;
  left: 5px; }

.pricing-container {
  padding: 30px 0 10px 0; }

.default-blue {
  color: #485890; }

/*************MIXINS*************/
/**********************************/
.signup-popup-wrapper {
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 10px 0; }

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #485890;
  font-size: 24;
  text-align: left;
  height: 565px;
  max-height: 565px; }

.signup-input-field, .react-datepicker__input-container input, .react-datepicker-wrapper input {
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  padding: 10px; }

.signup-input-field:focus, .react-datepicker__input-container input:focus, .react-datepicker-wrapper input:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE; }

.flex-center, .flex-center-wrap {
  display: flex;
  justify-content: center; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center-wrap {
  flex-wrap: wrap; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-align-center {
  display: flex;
  align-items: center; }

.hidden {
  display: none; }

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform; }

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #777; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #777; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #777; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.relative {
  position: relative; }

.pointer {
  cursor: pointer; }

/*****Validation message styles******/
.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important; }
  .loader div, .loader span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff; }

.loader-text {
  top: 53% !important;
  position: absolute;
  color: #fff; }

.left-loader-multi-word {
  right: 40% !important; }

.left-loader-one-word {
  right: 45% !important; }

.left-loader-two-words {
  right: 43% !important; }

ul {
  list-style: none;
  list-style-type: none; }

label {
  display: initial !important; }

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important; }

.opac-0-7 {
  opacity: 0.7 !important; }

.opac-1 {
  opacity: 1; }

.modal-header {
  font-size: 1.4rem !important; }

.video-container video {
  width: inherit !important; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-5px); }
  60% {
    transform: translateY(0px); } }

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom; }

.animate {
  animation-duration: 2s;
  animation-fill-mode: both; }

.btn-g {
  background: #23c97d !important;
  border-color: #23c97d !important; }

.btn-r {
  background: #FF0000 !important;
  border-color: #FF0000 !important; }

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

/* Modal styles **/
.btn-close {
  color: #aaaaaa;
  font-size: 20px;
  text-decoration: none;
  padding: 10px;
  position: absolute;
  right: 7px;
  top: 0;
  cursor: pointer; }

.btn-close:hover {
  color: #919191; }

.modale:before {
  content: "";
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10; }

.opened:before {
  display: block; }

.opened .__modal-dialog {
  transform: translate(0, 0);
  top: 10%; }

.__modal-dialog {
  background: #fefefe;
  border: #333333 solid 0px;
  border-radius: 25px;
  margin-left: -200px;
  text-align: left;
  position: fixed;
  left: 50%;
  top: -100%;
  z-index: 11;
  width: 420px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transform: translate(0, -500%);
  transition: transform 0.3s ease-out; }
  @media (min-width: 320px) and (max-width: 480px) {
    .__modal-dialog {
      height: auto;
      margin: 5% auto;
      max-height: 57em;
      max-width: 66em;
      width: 100%;
      left: 0; } }

.__modal-body {
  padding: 20px; }

.__modal-header,
.__modal-footer {
  padding: 10px 20px;
  text-align: center; }

.__modal-header {
  border-bottom: #eeeeee solid 1px; }

.__modal-header h2 {
  font-size: 20px; }

.formheader h2 {
  text-align: center;
  padding: 1% 2% 0% 1%; }

.formheader h4 {
  text-align: center;
  padding: 1% 2% 0% 1%; }

.formheader label {
  text-align: center;
  padding: 1% 2% 0% 1%; }

.formheader img {
  margin-left: 5%; }

.pg-viewer-wrapper {
  width: 100%;
  height: 90%;
  border: 0; }

.center {
  display: flex;
  justify-content: center;
  align-items: center; }

.formbody {
  color: #485890;
  top: 6% !important;
  /* Clear floats after the columns */ }
  .formbody .input-wrapper {
    margin-bottom: 10px;
    margin-left: 15px; }
  .formbody .column {
    float: left;
    width: 50%;
    padding: 1% 1% 1% 1%; }
  .formbody .topSpace {
    top: 20% !important; }
  .formbody label {
    margin-left: 15px; }
  .formbody .row:after {
    content: "";
    display: table;
    clear: both;
    margin-left: 10px; }

.dialog {
  top: 6% !important;
  height: 630px;
  border-radius: 25px;
  margin-top: 1%;
  margin-left: 20%;
  margin-right: 20%;
  padding: 1% 2% 4% 1%;
  background: white; }

.margin120 {
  margin-top: 120px; }

.margin65 {
  margin-top: 30px; }

.margin10 {
  margin-left: 50px; }

.labelField {
  padding-left: 6px; }

.btn-green {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #23c97d;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }

.btn-blue {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #2a377f;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }

.admin-form-validation-msg {
  color: #FF0000;
  margin-top: 0;
  position: absolute;
  font-size: 14px;
  right: 0;
  left: 5px; }

.email-exists-validation-msg {
  color: #FF0000;
  margin-top: -20px;
  position: absolute;
  font-size: 14px;
  right: none;
  left: 5px; }

.error-message {
  color: #FF0000;
  margin-top: 140px;
  position: absolute;
  font-size: 13px;
  right: none;
  left: 30%; }

.button-flex {
  justify-content: space-evenly; }

.medium-size-button {
  font-size: 25px; }

.signup-modal-content {
  background-color: transparent !important;
  border: none !important; }

.signup-modal-header {
  border: none !important; }

.signup-modal-content .close span {
  color: #ffffff; }

.default-blue {
  color: #485890; }

/* --------------- */
#subscription-table .plan {
  font: 13px;
  background: #fff;
  border: 1px solid #ddd;
  color: #333;
  padding: 20px;
  width: 30%;
  float: left;
  _display: inline;
  /* IE6 double margin fix */
  position: relative;
  margin: 0 5px;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  margin-bottom: 10px;
  flex-basis: 300px; }

#subscription-table .plan:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 10px;
  right: 4px;
  width: 70%;
  top: 80%;
  box-shadow: 0 12px 5px rgba(0, 0, 0, 0.3);
  transform: rotate(3deg); }

#subscription-table .popular-plan {
  top: -20px;
  padding: 40px 20px; }

/* --------------- */
#subscription-table {
  margin: 0 auto;
  text-align: center;
  width: 90%;
  /* total computed width */
  zoom: 1; }

#subscription-table:before, #subscription-table:after {
  content: "";
  display: table; }

#subscription-table .hide {
  content: "";
  display: none; }

#subscription-table:after {
  clear: both; }

/* --------------- */
#subscription-table .header {
  position: relative;
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  padding: 40px;
  margin: -20px -20px 20px -20px;
  border-bottom: 8px solid; }

#subscription-table .header:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  height: 30px;
  width: 100%;
  content: '';
  background-color: #026873;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.07) 50%, transparent 50%), linear-gradient(90deg, rgba(255, 255, 255, 0.13) 50%, transparent 50%), linear-gradient(90deg, transparent 50%, rgba(255, 255, 255, 0.17) 50%), linear-gradient(90deg, transparent 50%, rgba(255, 255, 255, 0.19) 50%);
  background-size: 13px, 29px, 37px, 53px; }

#subscription-table .popular-plan .header {
  margin-top: -40px;
  padding-top: 60px; }

/* --------------- */
#subscription-table .price {
  font-size: 20px; }

#subscription-table .monthly {
  font-size: 13px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #999;
  margin-top: 10px; }

/* --------------- */
#subscription-table ul {
  margin: 20px 0;
  padding: 0;
  list-style: none; }

#subscription-table li {
  padding: 10px 0; }

/* --------------- */
.active-plan {
  background: #485890;
  width: 100%;
  padding: 11px;
  color: #fff;
  border-radius: 25px; }
  .active-plan i {
    margin-right: 10px; }

/* Set height of body and the document to 100% to enable "full page tabs" */
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Titillium Web'; }

/* Style tab links */
.tablink {
  background-color: #1c2238;
  border-spacing: 1;
  border: none;
  color: white;
  float: left;
  outline: none !important;
  cursor: pointer;
  padding: 7px 8px;
  font-size: 24px; }

.tablink:hover {
  background-color: #2d3858;
  border-spacing: 1; }

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
  color: white;
  width: 100%;
  display: none;
  overflow-y: auto;
  padding: 10px 20px 5px 10px;
  height: 100%;
  background-color: #1c2238; }

/*TP-3949*/
.min-height-profile {
  min-height: 100vh; }

.default-blue {
  color: #485890; }

/*************MIXINS*************/
/**********************************/
.signup-popup-wrapper {
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 10px 0; }

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #485890;
  font-size: 24;
  text-align: left;
  height: 565px;
  max-height: 565px; }

.signup-input-field, .react-datepicker__input-container input, .react-datepicker-wrapper input {
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  padding: 10px; }

.signup-input-field:focus, .react-datepicker__input-container input:focus, .react-datepicker-wrapper input:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE; }

.flex-center, .flex-center-wrap {
  display: flex;
  justify-content: center; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center-wrap {
  flex-wrap: wrap; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-align-center {
  display: flex;
  align-items: center; }

.hidden {
  display: none; }

.hvr-grow, .icon-wrapper {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform; }

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #777; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #777; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #777; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.relative {
  position: relative; }

.pointer {
  cursor: pointer; }

/*****Validation message styles******/
.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important; }
  .loader div, .loader span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff; }

.loader-text {
  top: 53% !important;
  position: absolute;
  color: #fff; }

.left-loader-multi-word {
  right: 40% !important; }

.left-loader-one-word {
  right: 45% !important; }

.left-loader-two-words {
  right: 43% !important; }

ul {
  list-style: none;
  list-style-type: none; }

label {
  display: initial !important; }

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important; }

.opac-0-7 {
  opacity: 0.7 !important; }

.opac-1 {
  opacity: 1; }

.modal-header {
  font-size: 1.4rem !important; }

.video-container video {
  width: inherit !important; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-5px); }
  60% {
    transform: translateY(0px); } }

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom; }

.animate {
  animation-duration: 2s;
  animation-fill-mode: both; }

.btn-g {
  background: #23c97d !important;
  border-color: #23c97d !important; }

.btn-r {
  background: #FF0000 !important;
  border-color: #FF0000 !important; }

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

.__modal-body {
  color: #485890; }
  .__modal-body .input-wrapper {
    margin-bottom: 10px; }
  .__modal-body .margin-bottom-15 {
    margin-bottom: 15px; }

.__modal-header {
  color: #485890; }

.add-user-modal .__modal-dialog {
  top: 5%; }

.width-min {
  width: 600px; }

.formheader h2 {
  text-align: center;
  padding: 1% 2% 0% 1%; }

.formheader h4 {
  text-align: center;
  padding: 1% 2% 0% 1%; }

.formheader label {
  text-align: center;
  padding: 1% 2% 0% 1%; }

.formheader img {
  margin-left: 5%; }

.center {
  display: flex;
  justify-content: center;
  align-items: center; }

.formbody {
  top: 6% !important;
  /* Clear floats after the columns */ }
  .formbody .input-wrapper {
    margin-bottom: 10px;
    margin-left: 15px; }
  .formbody .column {
    float: left;
    width: 50%;
    padding: 1% 1% 1% 1%; }
  .formbody .topSpace {
    top: 20% !important; }
  .formbody label {
    margin-left: 15px; }
  .formbody .row:after {
    content: "";
    display: table;
    clear: both;
    margin-left: 10px; }

/*TP-3220*/
.form-color {
  background-color: #1c2238 !important; }

.form-text-color {
  color: #fff !important; }

.dialog, .signup-dialog {
  top: 6% !important;
  height: 630px;
  border-radius: 25px;
  margin-top: 1%;
  margin-left: 20%;
  margin-right: 20%;
  padding: 1% 2% 4% 1%; }

.signup-dialog {
  background: transparent; }

.margin10 {
  margin-left: 50px; }

.labelField {
  padding-left: 6px; }

.btn-red {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #FF0000;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }

.btn-green {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #23c97d;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }

.btn-green:hover, .btn-red:hover {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #7452cb;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }

.btn-blue {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #2a377f;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }

.btn-grey {
  display: block;
  height: 50px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #908d8d;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }

.groups-form-validation-msg {
  color: #FF0000;
  margin-top: 0px;
  position: absolute;
  font-size: 12px;
  right: 0px;
  left: none; }

.job-form-validation-msg {
  color: #FF0000;
  margin-top: 0px;
  position: absolute;
  font-size: 12px;
  right: 15px;
  left: none; }

.__modal-header-add {
  padding: 2px 16px;
  background-color: #7452cb;
  color: white; }

.search-input-wrapper i {
  position: absolute;
  top: 7px;
  left: 10px;
  font-size: 18px;
  color: #ddd;
  z-index: 0; }

.search-input-wrapper input {
  background-color: #384571;
  width: 100%;
  font-size: 18px;
  padding: 5px 10px 5px 10px;
  margin-bottom: 2px;
  color: white;
  border-radius: 3px;
  padding: 5px 5px 7px 35px;
  border: none; }

.btn-green-add {
  position: relative;
  outline: none;
  width: 40px;
  height: 40px;
  margin-right: 10px; }

th {
  background-color: #7452cb;
  font-size: 21px;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  position: relative; }

/*TP-3259*/
#groupsTable th,
#groupsTable td,
#usersTable th,
#usersTable td,
#jobsTable th,
#jobsTable td,
#assJobsTable th,
#assJobsTable td,
#assAssetsTable th,
#assAssetsTable td {
  text-align: left;
  padding: 1px 6px 1px 6px;
  position: relative; }

#usageTable th,
#usageTable td,
#missedCallTable th,
#missedCallTable td {
  text-align: left;
  padding: 2px 6px 2px 6px;
  position: relative; }

td.actions {
  display: flex;
  justify-content: space-around; }

/**TZ-475*/
.first-child-missed-call {
  background-color: #cbd4f5 !important;
  color: black !important; }

tr:nth-child(odd) {
  background-color: #384571;
  border-radius: 3px; }

tr:nth-child(even) {
  background-color: #2d3858;
  border-radius: 3px; }

.trans-table tr:nth-child(odd) {
  background-color: #FFFFFF;
  border-bottom: 3px solid #000000; }

.trans-table tr:nth-child(even) {
  background-color: #F2F2F2;
  border-bottom: 3px solid #000000; }

.icon-wrapper {
  font-size: 19px; }
  .icon-wrapper .fa-video {
    color: #23c97d; }
  .icon-wrapper .fa-video-disabled {
    color: gray !important; }
  .icon-wrapper .fa-trash-alt {
    color: #cd4d4d; }
  .icon-wrapper :hover {
    transform: scale(1.1); }

.add-users-validation-msg {
  color: #FF0000;
  margin-top: 0px;
  position: absolute;
  font-size: 12px;
  right: 0px;
  left: none; }

.profile-validation-msg {
  color: #FF0000;
  margin-top: 0px;
  position: absolute;
  font-size: 12px;
  right: auto;
  left: none; }

.user-type-flag {
  padding: 0px 8px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: bold; }

.Invitee {
  background: #777; }

.Technician {
  background: #71a95a; }

.Expert {
  background: #485890; }

.Inactive {
  background: #c94e4a; }

.Admin {
  background: #89783f; }

.admin-expert {
  background: #89783f; }

.pagination-wrapper {
  margin-top: 4px;
  right: 20px !important;
  left: inherit !important;
  margin-bottom: 5% !important;
  max-height: 15px !important;
  /*TP-2883*/ }
  .pagination-wrapper .pagination {
    margin-bottom: 0 !important; }

tr.no-sort th {
  cursor: default; }

.align-center {
  margin: 0 auto; }

.margin-b-10 {
  margin-bottom: 10px; }

.custom-date {
  margin-top: 1px !important; }

.stream-mode-desc {
  padding: 5px;
  background: #485890;
  border-radius: 5px; }

.call-notification-toast {
  margin: 0 auto; }
  .call-notification-toast .call-notification-toast-body {
    background: #fff;
    color: #6c757d; }

.red-trash {
  color: red; }

.bg-created {
  background-color: #d19a52 !important; }

.bg-assigned {
  background-color: #bfe8f2 !important; }

.bg-in-progress {
  background-color: #eaf099 !important; }

.bg-finished {
  background-color: #defde0 !important; }

.bg-white {
  background-color: #ffffff !important; }

/*TP-3220, TP-3222, TP-3223 -- new color background for New Task created*/
.bg-new-task {
  background-color: #a432d1 !important; }

#Download .fa-file-csv, #Download .fa-long-arrow-alt-down {
  color: #fff; }

.recording-url, a.recording-url:hover, a.recording-url:focus, a.recording-url:active {
  /* color: #fff; */
  cursor: pointer; }

/*TP-2892 -- CSS for Mobile specific class*/
.group-dropdown {
  min-height: 40px;
  width: 100%;
  padding: 0 5px 0 10px;
  border-radius: 5px;
  border-color: #999da1;
  color: gray; }

.group-option {
  height: 30px !important;
  font-size: 150% !important;
  color: gray;
  border-radius: 5px; }

.hidden-optn {
  display: none; }

/*TP-3259*/
.show-ellipses {
  line-height: 1em;
  height: 2em;
  /* height is 2x line-height, so two lines will display */
  overflow: hidden;
  /* prevents extra lines from being visible */ }

.width-header {
  padding: 1% 15% 0% 15% !important; }

.bright-green-font {
  color: #39FF14 !important; }

.grey-font {
  color: #a1a1a1 !important; }

.profile-header {
  color: #7452cb !important; }

/*TP-4871*/
.center-align {
  text-align: center !important; }

.max-width-profile {
  max-width: 45px !important; }

.min-width-profile {
  min-width: 56px !important; }

/*TP-6222*/
.chat-page-container {
  width: 100px;
  left: 50%; }

.chat-parent-container {
  margin-top: 3px; }

/*TP-3423*/
.default-blue {
  color: #485890; }

/*************MIXINS*************/
/**********************************/
.signup-popup-wrapper {
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 10px 0; }

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #485890;
  font-size: 24;
  text-align: left;
  height: 565px;
  max-height: 565px; }

.signup-input-field, .react-datepicker__input-container input, .react-datepicker-wrapper input {
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  padding: 10px; }

.signup-input-field:focus, .react-datepicker__input-container input:focus, .react-datepicker-wrapper input:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE; }

.flex-center, .flex-center-wrap {
  display: flex;
  justify-content: center; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center-wrap {
  flex-wrap: wrap; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-align-center {
  display: flex;
  align-items: center; }

.hidden {
  display: none; }

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform; }

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #777; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #777; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #777; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.relative {
  position: relative; }

.pointer {
  cursor: pointer; }

/*****Validation message styles******/
.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important; }
  .loader div, .loader span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff; }

.loader-text {
  top: 53% !important;
  position: absolute;
  color: #fff; }

.left-loader-multi-word {
  right: 40% !important; }

.left-loader-one-word {
  right: 45% !important; }

.left-loader-two-words {
  right: 43% !important; }

ul {
  list-style: none;
  list-style-type: none; }

label {
  display: initial !important; }

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important; }

.opac-0-7 {
  opacity: 0.7 !important; }

.opac-1 {
  opacity: 1; }

.modal-header {
  font-size: 1.4rem !important; }

.video-container video {
  width: inherit !important; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-5px); }
  60% {
    transform: translateY(0px); } }

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom; }

.animate {
  animation-duration: 2s;
  animation-fill-mode: both; }

.btn-g {
  background: #23c97d !important;
  border-color: #23c97d !important; }

.btn-r {
  background: #FF0000 !important;
  border-color: #FF0000 !important; }

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

* {
  margin: 0;
  padding: 0; }

/* To give the containers
   in spherical shape */
.dot {
  border-radius: 5%; }

.dot span {
  position: absolute;
  display: block;
  border: 5px;
  border-radius: 5%; }

.dot .row-div {
  /* animation: animate 2s ease-in; */ }

/*the animation*/
@keyframes animate {
  0% {
    /* transform: translateY(-300px); */
    height: 0px;
    width: 0px; }
  50% {
    /* transform: translateY(190px);*/
    width: +200px;
    height: 100px; }
  100% {
    /* transform: translateY(-300px); */
    height: 200px;
    width: 400px; } }

/* The bubble is defined with the following
   Set the color, delay and
   duration(i.e different speed) */
.dot span:nth-child(1) {
  top: 15px;
  left: 50px;
  position: absolute;
  z-index: 9999;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 450px;
  /*TP-3484*/
  border-radius: 5%;
  background-color: #384571;
  animation-delay: 0.3s;
  animation-duration: 2.6s; }

.overflow {
  overflow-x: auto;
  overflow-y: scroll; }

.row-div {
  width: 410px;
  /*TP-3875*/
  padding: 15px 10px 15px 10px; }

.wrap-content {
  word-wrap: break-word; }

/*TP-3657*/
.Invitee {
  background: #777 !important; }

.Technician {
  background: #71a95a !important; }

.Expert {
  background: #485890 !important; }

.Inactive {
  background: #c94e4a !important; }

.Admin {
  background: #89783f !important; }

.admin-expert {
  background: #89783f !important; }

.bubble-user-type-flag {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin: 10px -50px !important;
  padding: 0px 8px;
  border-radius: 5px !important;
  font-size: 13px;
  font-weight: bold; }

.ring-container {
  position: relative;
  width: 15px; }

.circle {
  width: 9px;
  height: 9px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  left: 0px; }

.ringring {
  border: 3px solid #62bd19;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  height: 17px;
  width: 17px;
  position: absolute;
  left: -4px;
  animation: pulsate 1s ease-out;
  -webkit-animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  opacity: 0.0; }

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0; }
  50% {
    opacity: 1.0; }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0; } }

.confirm-modal .__modal-body h4 {
  margin-bottom: 10px;
  margin-top: 0; }

.confirm-modal .__modal-footer .btn-cancel {
  margin-right: 10px; }

div.close-square {
  border: solid 21px;
  color:white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  left: 50%;
}
.close3d {
  width: 25px;
  height: 25px;
  opacity: 0.3;
  
}
.close3d:hover {
  opacity: 1;
  border-radius: 50%;
}
.close3d:before, .close3d:after {
  position: absolute;
  top: -13px;
  left: 0px;
  content: ' ';
  height: 28px;
  width: 2px;
  background-color: black;
}
.close3d:before {
  transform: rotate(45deg);
}
.close3d:after {
  transform: rotate(-45deg);
}
/*TP-6222*/
.chatwindownew {
    background-color: white !important;
    display: flex;
    position: fixed;
    border-color: white;
    z-index: 1050;
    right: 33% !important;
    bottom: 10px;
    border-radius: 0px 10px 10px 0px;
  }

  .chattopsection {
    height: 100px;
  }

  .profile-image {
    margin: 5px 10px;
  }

  .topsectionleft {
    margin: 5px 10px;    
  }

  .chattopicon {
    color: black;
    padding: 10px 5px;
    margin: 10px;    
  }

  .chattopicon .fa-video {
    font-size: 30px;
    margin: 0px 55px;
  }
  
  .chattopicon .fa-phone {
    font-size: 30px;
    margin: 0 20px;
  }

  .topname {
    color: #384571;
    font-size: 23px;
    padding: 7px 0;
    margin: 10px;
  }

  .topname .fa-asterisk {
    /* color: #384571; */
  }

  .badge-type {
    margin-left: 10px;
  }

  .chattop-seperator {
    background-color: #384571;
    width: 95%;
    margin: 10px 10px;
    box-shadow: 2px 2px 2px #384571;
  }
  
  .chatreadonlynew {
    background-color: white !important;
    display: none;
    position: fixed;
    width: 100%;
    height: 90%;
    overflow: auto;
    border-radius: 10px 10px 0px 0px;
  }
  
  .chatInputBar{
    bottom: 10px;
    position: absolute;
    display: none;
    width: 100%;    
  }
  
  .chatdivblob{
    background-color: transparent;
    /* cursor: none; */
    font-size: 15px;
    width: 100%;
    height: auto;
  }
  
  .chatmsgblob{
    background-color: rgb(56, 69, 113); /*#2d3858;*/
    color: white;
    text-align: left;
    /*border-radius: 25px;
    cursor: none; */
    font-size: 16px;
    position: absolute;
    width: 85% !important;
    height: auto;
    font-family: 'Titillium Web' !important;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* margin-left: 5px; */
    position: relative;
    display: inline;
  }
  
  .chatmsgblobright {
    border-radius: 0 25px 25px 25px !important;
  }

  .chatmsgblobleft {
    border-radius: 25px 0 25px 25px !important;
  }
  .chatmsgmultilineblob{
    background-color: rgb(56, 69, 113); /*#2d3858;*/
    color: white;
    text-align: left;
    /* border-radius: 25px;
    cursor: none; */
    font-size: 16px;
    position: absolute;
    width: 85% !important;
    height: auto;
    font-family: 'Titillium Web' !important;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 2px;
    margin-left: 20px;
    word-wrap: normal;
    position: relative;
  }
  .initialblob {  
    text-align: center;
    position: absolute;
    width: 100%;
    background-color: #23c97d;
    color: white;
    border-radius: 50%;
    /* cursor: none; */  
    text-align: center;
    padding: 3px 0;
  }
  
  /*TP-3200*/
  .chatidnewblob {
    color: white;
    border-radius: 50%;
    /* cursor: none; */
    font-size: 18px;
    width: 32px;
    height: 32px;
    text-align: center;
    padding: 3px 0;
    display: inline;
  }
  
  .chatidblob{
    background-color: #7452cb;
    color: white;
    border-radius: 50%;
    /* cursor: none; */
    font-size: 18px;
    width: 32px;
    height: 32px;
    text-align: center;
    padding: 3px 0;
    display: inline;
  }
  /*TP-3200*/
  .idnewright {
    float: left;
  }
  
  .idright{
    float: left;/*right;*/
    background-color: #1fd17e;
  }
  
  /*TP-3200*/
  .idnewleft {
    float: left;
  }
  
  .idleft{
    float: left;
    background-color: #7452cb;
  }
  
  .chatspangap{
    background-color: transparent;
    float: left ;
    width: 100%;
    height: 5px;
    font-family: 'Titillium Web' !important;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  
  .chattimeblob{
    background-color: transparent;
    color:  /* white; */ rgb(56, 69, 113) !important; /*#384571;*/
    /* text-shadow: 2px 2px 2px black; */
    border-radius: 10%;
    /* float: left ; */
    width: 100%;
    height: 20px;
    font-family: 'Titillium Web' !important;
    /* text-align: center; */
    margin-top: 8px;
    margin-bottom: 5px;
  }

  .chattimerightblob{
    text-align: right;
    padding-right: 15%;
  }

  .chattimeleftblob{
    text-align: left;
    padding-left: 8%;
  }

  .chatreplyblob {
    border-color: white;
    border-radius: 10px 10px 0 0;
    /* background: white !important; */
    
    /* text-shadow: 2px 2px 2px #384571; */
    width: 100%;
    /* height: 50px;  */   
    font-family: 'Titillium Web' !important;
    text-align: left;
    margin: 0 0 0 0px;
    /* padding: 0 15px; */
  }

  .divider-cls {
    background-color: white;
    width: 98%;
    margin: 8px 0;
  }

  .chatreplyblobmessage {
    color: #09ebab;
  }

  .chatReplyBubble {
    border-color: white;
    border-radius: 10px 10px 0 0;
    background: white !important;
    
    /* text-shadow: 2px 2px 2px #384571; */
    width: 99%;
    /* height: 50px;  */   
    font-family: 'Titillium Web' !important;
    text-align: left;
    margin-top: 8px;
    padding: 0 15px;
    /* margin-bottom: 5px; */
  }

  .replyclosebtn {
    color: black;
    float: right;
    padding: 5px 15px;
    cursor: pointer;
  }

  .chatreplyspanname {
    color: red;
  }

  .chatreplyspanmessage {
    color: blue;
  }
  
  .textareaIM {
    background-color: rgb(56, 69, 113) !important;
    bottom: 5px !important;
    border: none;
    color: white;
    font-family: 'Titillium Web' !important;
    height: 40px;
    width: 99%;
    float: left !important;
    left: 5px !important;
    padding-left: 5px;
    display:inline;
    padding-right: 40px;
    border-radius: 15px;
    margin-left: 2.5px;
  }

  .chat-search-box {
    background-color: rgb(56, 69, 113) !important;
    bottom: 5px !important;
    border: none;
    color: white;
    font-family: 'Titillium Web' !important;
    height: 45px;
    width: 95%;
    float: left !important;
    left: 5px !important;
    padding-left: 12px;
    display:inline;
    padding-right: 40px;
    border-radius: 15px;
    margin-left: 3.5px;
    margin-top: 1rem;
  }
  
  
  .sendchatbtn {
    background-color: #384571 !important;
    color: white;
    border-radius:50%;
    font-size: 21px;
    margin-left: -40px;
    /* margin-top: 10px; */
    bottom: 10px !important;
     position: absolute;
   vertical-align:-webkit-baseline-middle;
    display:inline;
    cursor:pointer;
  }
  
  .fa-sendchat-circle {
    background-color: #384571 !important;
    color: #384571 !important;
    border-radius: 50%;
    font-size: 32px;
  }

  .bubble {
    height: 50px;
    width:200px;
    top: 15px;
    left: 200px;
    position: absolute;
    z-index: 9999;
    /* height: fit-content; */
    /*width: 450px; TP-3484*/
    border-radius: 10px;
    background-color:#7452cb; /* #384571; */ 
    animation-delay: 0.3s;
    animation-duration: 2.6s;
  }

  .bubble span {
    padding: 10px 10px 10px 20px;
  }

  .bubble-left span {
    padding: 10px 10px 10px 50px !important;
  }

  .deletedblog {
    background-color: gray;
    color: black;
    font-style: italic;
  }

  .edited-section {
    color: gray;
    font-style: italic;
    float: right;
  }

  .chat-pic-new{
    border-radius: 50%;
    height: 35px;
    width: 35px;
    object-fit: cover;
    vertical-align: middle;
    margin-top: -35px;
  }
  
/*TP-6222*/
.side-panel-parent {
    position: absolute;
    top: 146px;
    bottom: 120px;
    border-radius: 10px 0px 0px 10px;
    background-color: white;
    width:270px;
}
.panel {
    border-radius: 20px;
    width: 95%;
    margin-left: 5px !important;
}
.items {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 1rem;
    background-color: #1c2238;
    margin-top: 5px;
}
.items:hover {
    background-color: rgb(45, 56, 88);
}
.panel-active {
    background-color: rgb(45, 56, 88);
}

.searchchatbtn {
    background-color: #384571 !important;
    color: white;
    border-radius:50%;
    font-size: 21px;
    margin-left: -40px;
    margin-top: 27px;
    /* bottom: 10px !important; */
     position: absolute;
   vertical-align:-webkit-baseline-middle;
    display:inline;
    cursor:pointer;
  }

  lastseen-timecls {
    color: gray !important;
  }
.flash-message {
  position: fixed;
  /* top: 30px;
  left: 40px; */
  z-index: 1060;
  background-color: white;
  padding: 0.5% 1% 0.5% 1%;
  border-radius: 10px;
  border-style: solid black;
  color: #384571;
  font-size: 22px;
  transition: .2s;
  transform: translateY(16px);
  transform-origin: top right;
  opacity: 0;
  visibility: hidden; }

.flash-message--is-visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0); }

/*TP-1518*/
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.default-blue {
  color: #485890; }

/*************MIXINS*************/
/**********************************/
.signup-popup-wrapper {
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 10px 0; }

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #485890;
  font-size: 24;
  text-align: left;
  height: 565px;
  max-height: 565px; }

.signup-input-field, .react-datepicker__input-container input, .react-datepicker-wrapper input {
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  padding: 10px; }

.signup-input-field:focus, .react-datepicker__input-container input:focus, .react-datepicker-wrapper input:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE; }

.flex-center, .flex-center-wrap {
  display: flex;
  justify-content: center; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center-wrap {
  flex-wrap: wrap; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-align-center {
  display: flex;
  align-items: center; }

.hidden {
  display: none; }

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform; }

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #777; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #777; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #777; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.relative {
  position: relative; }

.pointer {
  cursor: pointer; }

/*****Validation message styles******/
.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important; }
  .loader div, .loader span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff; }

.loader-text {
  top: 53% !important;
  position: absolute;
  color: #fff; }

.left-loader-multi-word {
  right: 40% !important; }

.left-loader-one-word {
  right: 45% !important; }

.left-loader-two-words {
  right: 43% !important; }

ul {
  list-style: none;
  list-style-type: none; }

label {
  display: initial !important; }

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important; }

.opac-0-7 {
  opacity: 0.7 !important; }

.opac-1 {
  opacity: 1; }

.modal-header {
  font-size: 1.4rem !important; }

.video-container video {
  width: inherit !important; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-5px); }
  60% {
    transform: translateY(0px); } }

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom; }

.animate {
  animation-duration: 2s;
  animation-fill-mode: both; }

.btn-g {
  background: #23c97d !important;
  border-color: #23c97d !important; }

.btn-r {
  background: #FF0000 !important;
  border-color: #FF0000 !important; }

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

#jobsTable > th {
  background-color: #7452cb;
  font-size: 21px;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  position: relative; }

td .fa-file-pdf-o-read {
  color: #C0FF02; }

#job-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  grid-gap: 40px;
  gap: 40px;
  margin-left: auto; }

.dropdown-content {
  position: absolute;
  min-width: 50px;
  color: black;
  background-color: aliceblue;
  z-index: 10; }
  .dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block; }
  .dropdown-content a:hover {
    color: black !important;
    scale: 0.9;
    transform: scale(0.9); }

/*TP-3220, TP-3222, TP-3223*/
.jobs-form-body .input-wrapper {
  margin-bottom: 10px; }

.jobs-form-body .margin-bottom-15 {
  margin-bottom: 15px; }

.jobs-form-body .margin-top-15 {
  margin-top: 15px; }

.jobs-form-body .text-black {
  color: black; }

.width-min {
  width: 800px; }

.procedure-buttons-container {
  padding-left: 13px; }
  .procedure-buttons-container .task-buttons {
    border-radius: 30%;
    border-color: #1c2238;
    width: 40px;
    /* background-color: aqua; */
    -webkit-text-emphasis: 100px;
            text-emphasis: 100px; }
  .procedure-buttons-container .task-buttons:hover {
    background-color: #7452cb !important; }
  .procedure-buttons-container .task-buttons:active {
    background-color: #5f3575 !important; }

.centering {
  display: flex;
  justify-content: center;
  align-items: center; }

.job-button {
  font-size: 18px; }

.btn-green-medium {
  display: block;
  height: 30px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 15px;
  background-color: #2a377f;
  color: white;
  padding: 0 15px 0 15px;
  min-width: 110px; }

.btn-green-medium:hover {
  display: block;
  height: 30px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 15px;
  background-color: #2a377f;
  color: white;
  padding: 0 15px 0 15px;
  min-width: 110px; }

.bg-selected {
  background-color: #2d3858; }

.position-sticky {
  position: -webkit-sticky;
  position: sticky; }

a.white:hover {
  color: white; }


/* Rounded sliders */
#job-wrapper .slider.round {
    border-radius: 34px;
  }
  
  #job-wrapper .slider.round:before {
    border-radius: 50%;
  }
  
  /*Rounder slider end*/

  #job-wrapper .switch {
    position: relative;
    display: inline-block !important;
    width: 50px;
    height: 25px;
    margin-left: 2px;
  }
  
  #job-wrapper .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  #job-wrapper .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  #job-wrapper .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  #job-wrapper input:checked+.slider {
    background-color: #2196F3;
  }
  
  #job-wrapper input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  #job-wrapper input:checked+.slider:before {
    transform: translateX(26px);
  }

  .pd-1 {
    padding-top: 1%;
  }
.default-blue {
  color: #485890; }

/*************MIXINS*************/
/**********************************/
.signup-popup-wrapper {
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 10px 0; }

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #485890;
  font-size: 24;
  text-align: left;
  height: 565px;
  max-height: 565px; }

.signup-input-field, .react-datepicker__input-container input, .react-datepicker-wrapper input {
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  padding: 10px; }

.signup-input-field:focus, .react-datepicker__input-container input:focus, .react-datepicker-wrapper input:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE; }

.flex-center, .flex-center-wrap {
  display: flex;
  justify-content: center; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center-wrap {
  flex-wrap: wrap; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-align-center {
  display: flex;
  align-items: center; }

.hidden {
  display: none; }

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform; }

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #777; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #777; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #777; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.relative {
  position: relative; }

.pointer {
  cursor: pointer; }

/*****Validation message styles******/
.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important; }
  .loader div, .loader span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff; }

.loader-text {
  top: 53% !important;
  position: absolute;
  color: #fff; }

.left-loader-multi-word {
  right: 40% !important; }

.left-loader-one-word {
  right: 45% !important; }

.left-loader-two-words {
  right: 43% !important; }

ul {
  list-style: none;
  list-style-type: none; }

label {
  display: initial !important; }

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important; }

.opac-0-7 {
  opacity: 0.7 !important; }

.opac-1 {
  opacity: 1; }

.modal-header {
  font-size: 1.4rem !important; }

.video-container video {
  width: inherit !important; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-5px); }
  60% {
    transform: translateY(0px); } }

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom; }

.animate {
  animation-duration: 2s;
  animation-fill-mode: both; }

.btn-g {
  background: #23c97d !important;
  border-color: #23c97d !important; }

.btn-r {
  background: #FF0000 !important;
  border-color: #FF0000 !important; }

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

.legend {
  padding: 0 10px 0 10px; }

.account-info {
  background: #384571;
  align-self: center;
  width: 330px;
  padding: 10px;
  margin-bottom: 3px; }
  .account-info h4 {
    margin: 0; }
  .account-info .summary-header {
    font-size: 18px; }
    .account-info .summary-header .fa {
      margin-left: 10px; }

.trial-period-end-text {
  color: #dc3545; }

.trial-badge {
  margin-left: 10px; }

a {
  text-decoration: none;
  color: inherit; }

.tbl-card-list th, .tbl-card-list td {
  padding: 0.35rem !important; }

.tbl-card-list th {
  font-size: 14px !important; }

.tbl-card-list td {
  font-size: 12px;
  vertical-align: middle !important; }

.brand-icon img {
  height: 20px;
  width: auto; }

.ad-btn-green {
  display: block;
  height: 40px;
  width: auto;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: #23c97d;
  color: white;
  padding: 0 25px 0 25px;
  min-width: 110px; }

.modal-loader {
  width: inherit;
  cursor: wait;
  position: absolute;
  top: 0;
  right: 44%; }

.user-units-container .item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px; }

.user-units-container .quantity input {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 60px;
  margin-left: 5px;
  font-size: 22px;
  color: #43484D;
  font-weight: 300; }

.user-units-container .totals-item {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; }

.user-units-container .plus-btn, .user-units-container .minus-btn {
  width: 30px;
  height: 30px;
  background-color: #E1E8EE;
  border-radius: 6px;
  border: none;
  cursor: pointer; }

.user-units-container button:focus,
.user-units-container input:focus {
  outline: 0; }

.user-units-container .total-price {
  width: 83px;
  text-align: center;
  font-size: 22px;
  color: #43484D;
  font-weight: 300; }

.user-units-container .description span {
  display: block;
  font-size: 22px;
  color: #43484D;
  font-weight: 400; }

.user-units-container .h5-text {
  margin-top: 20px;
  margin-bottom: 0; }

@media (max-width: 800px) {
  .user-units-container .item {
    height: auto;
    flex-wrap: wrap;
    justify-content: center; }
  .user-units-container .image,
  .user-units-container .quantity,
  .user-units-container .description {
    display: flex;
    align-items: center; }
  .user-units-container .buttons {
    margin-right: 20px; } }

#closePlayer > .fa-circle {
    color: red !important;
  }
  
  .close-file-icon {
    z-index: 11;
    color: #fff !important;
  }
  
  #closePlayer > .fa-circle:hover {
      color:lightblue;  
  }
  
  .recording-label {
    color: white;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-55%, -50%);
  }

.recording-container {
    position: relative;
    text-align: center;
}

.hide-button {
  opacity: 0;
}

.hide-button:hover {
  transition: 100ms;
  opacity: 1;
}

.video-tile:hover + .hide-button {
  transition: 100ms;
  opacity: 1;
}


.play-button{  
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-55%, -50%);
}

/* .hover-grid:hover {
  transition: 500ms;
  height: 103%; 
  width: 101%;
} */


/*TP-3328*/
/* Rounded sliders */
 .slider.round {
    border-radius: 34px;
  }
  
   .slider.round:before {
    border-radius: 50%;
  }
  
  /*Rounder slider end*/

   .switch {
    position: relative;
    display: inline-block !important;
    width: 50px;
    height: 25px;
    margin-left: 2px;
  }
  
   .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
   .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
   .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
   input:checked+.slider {
    background-color: #2196F3;
  }

  /*TP-3871*/
  .grayed-class {
    color: darkslategray !important;
    text-shadow: 0px 1px 0px rgba(255,255,255,.5); /* 50% white from bottom */
  }

    /* TP-3871-- input:disabled+.slider{
      background-color: gray;
    } */
  
   input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
   input:checked+.slider:before {
    transform: translateX(26px);
  }

  .pd-1 {
    padding-top: 1%;
  }
/*TP-3328*/
#my_editor {
  order: 2; }

#profile-controls {
  order: 1; }

.margin-photo-editor {
  margin-right: 10%; }

.scale-div input {
  cursor: pointer; }

.color-icon {
  color: black;
  z-index: 9999; }

.color-grey {
  color: grey; }

.back-icon {
  font-size: 30px;
  cursor: pointer; }

/*TP-3497*/
.remove-icon {
  font-size: 30px;
  cursor: pointer; }

.upload-icon {
  font-size: 30px;
  cursor: pointer; }

.save-icon {
  font-size: 30px;
  cursor: pointer; }

@media screen and (max-width: 1024px) {
  .profile-controls {
    height: 35px;
    margin-top: -20% !important;
    margin-left: 18% !important; }
  /*TP-3497*/
  .position-dropdown-profile {
    /* margin-top: 21%; */
    margin-left: 55%;
    border-radius: 10px; }
  /*TP-3672*/
  .my-profile-pic {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    margin-right: 1.5%;
    cursor: pointer;
    object-fit: cover;
    /* float: right; */ }
  .edit-icon {
    margin-left: -55px;
    margin-top: 168px;
    font-size: 30px;
    cursor: pointer; } }

@media screen and (min-width: 1025px) {
  .profile-controls {
    height: 35px;
    margin-top: -20% !important;
    margin-left: 10% !important;
    padding-left: 10% !important;
    /*TP-3497*/ }
  /*TP-3497*/
  .position-dropdown-profile {
    /* margin-top: 21%; */
    margin-left: 55%;
    border-radius: 10px; }
  /*TP-3672*/
  .my-profile-pic {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    margin-right: 1.5%;
    cursor: pointer;
    object-fit: cover;
    /* float: right; */ }
  .edit-icon {
    margin-left: -55px;
    margin-top: 168px;
    font-size: 30px;
    cursor: pointer; } }

@media screen and (max-width: 992px) {
  .profile-controls {
    height: 35px;
    margin-top: -20% !important;
    margin-left: 10% !important;
    padding-left: 10% !important; }
  /*TP-3497*/
  .position-dropdown-profile {
    margin-top: -14%;
    margin-left: 61%;
    border-radius: 10px; }
  /*TP-3672*/
  .my-profile-pic {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin-right: 1.5%;
    cursor: pointer;
    object-fit: cover;
    /* float: right; */ }
  .edit-icon {
    margin-left: -55px;
    margin-top: 140px;
    font-size: 30px;
    cursor: pointer; } }

/*TP-3199*/
.photo-modal-body canvas {
  border-radius: 10%;
  background-color: white; }

.text-black {
  color: black; }

.width-profile {
  width: 85%; }

input .disable-class {
  color: grey; }

.center {
  margin-right: auto !important;
  margin-left: auto !important; }

/*TP-4664*/
.nocameracls {
  color: red !important; }

.profile-otp {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 6px;
  color: #fff;
  font-size: 22px;
  outline: none;
  padding: 3px;
  max-width: 40px; }

.default-blue {
  color: #485890; }

.__modal-body .input-wrapper {
  margin-bottom: 35px; }

.__modal-body ul > li {
  margin-left: 5px !important; }

.fa-icons {
  font-size: 18px;
  font-weight: 1000;
  margin-left: 0;
  padding-left: 0;
  padding-right: 5px; }

.check {
  color: #23c97d; }

.times {
  color: red; }

.collapse-text ul {
  margin: 18px !important;
  padding: 0 !important; }

/* .collapse-text ol {
    margin: 0 !important;
} */
.collapse {
  cursor: pointer; }

#ellipsis-ex {
  cursor: pointer; }

.text-truncate {
  display: inline-block;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* @import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

body, html {
    background-color: black;
    height: 100%;
  font-family: 'Titillium Web';
} */

/* .flex-container > div {
  background-color: #000000;
  border-color: black;
} */

.OT_widget-container{
  border-radius: 10px;
}

.OT_subscriber.OT_video-element{
  border-radius: 10px;
}

.OT_widget-container.OT_video-poster {
  border-radius: 10px;
}

.OT_bar.OT_edge-bar-item.OT_mode-on{
  border-radius: 10px 10px 0px 0px;
}

#publisherContainer, #expertscreenshare {
  margin: auto;
  /* width: 50%; */
  /* border: 3px solid green;
  padding: 10px; */
}

.chatwindow {
  background-color: #1c223833 !important;
  display: flex;
  position: fixed;
  border-color: white;
  z-index: 1070;
  right: 10px;
  bottom: 10px;
}

.chatreadonly {
  background-color: #1c223833 !important;
  display: none;
  position: fixed;
  width: 100%;
  height: 90%;
  overflow: auto;
}

.chatInputBar{
  bottom: 10px;
  position: absolute;
  display: none;
  width: 100%;
  height: 40px;
}

.chatdivblob{
  background-color: transparent;
  /* cursor: none; */
  font-size: 15px;
  width: 100%;
  height: auto;
}

.chatmsgblob{
  background-color: rgb(56, 69, 113); /*#2d3858;*/
  color: white;
  text-align: left;
  border-radius: 25px;
  /* cursor: none; */
  font-size: 16px;
  position: absolute;
  width: 85% !important;
  height: auto;
  font-family: 'Titillium Web' !important;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* margin-left: 5px; */
  position: relative;
  display: inline;
}


.chatmsgmultilineblob{
  background-color: rgb(56, 69, 113); /*#2d3858;*/
  color: white;
  text-align: left;
  border-radius: 25px;
  /* cursor: none; */
  font-size: 16px;
  position: absolute;
  width: 85% !important;
  height: auto;
  font-family: 'Titillium Web' !important;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 2px;
  margin-left: 20px;
  word-wrap: normal;
  position: relative;
}
.initialblob {  
  text-align: center;
  position: absolute;
  width: 100%;
  background-color: #23c97d;
  color: white;
  border-radius: 50%;
  /* cursor: none; */  
  text-align: center;
  padding: 3px 0;
}

/*TP-3200*/
.chatidnewblob {
  color: white;
  border-radius: 50%;
  /* cursor: none; */
  font-size: 18px;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 3px 0;
  display: inline;
}

.chatidblob{
  background-color: #7452cb;
  color: white;
  border-radius: 50%;
  /* cursor: none; */
  font-size: 18px;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 3px 0;
  display: inline;
}
/*TP-3200*/
.idnewright {
  float: left;
}

.idright{
  float: left;/*right;*/
  background-color: #1fd17e;
}

/*TP-3200*/
.idnewleft {
  float: left;
}

.idleft{
  float: left;
  background-color: #7452cb;
}

.chatspangap{
  background-color: transparent;
  float: left ;
  width: 100%;
  height: 5px;
  font-family: 'Titillium Web' !important;
  margin-top: 2px;
  margin-bottom: 2px;
}

.chattimeblob{
  background-color: transparent;
  color:  white !important; /* rgb(56, 69, 113) */; /*#384571;*/
  text-shadow: 2px 2px 2px black;
  border-radius: 10%;
  float: left ;
  width: 100%;
  height: 20px;
  font-family: 'Titillium Web' !important;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 5px;
}

.textareaIM {
  background-color: rgb(56, 69, 113) !important;
  bottom: 5px !important;
  border: none;
  color: white;
  font-family: 'Titillium Web' !important;
  height: 40px;
  width: 99%;
  float: left !important;
  left: 5px !important;
  padding-left: 5px;
  display:inline;
  padding-right: 40px;
}


.sendchatbtn {
  background-color: #384571 !important;
  color: white;
  border-radius:50%;
  font-size: 21px;
  margin-left: -40px;
  /* margin-top: 10px; */
  bottom: 10px !important;
   position: absolute;
 vertical-align:-webkit-baseline-middle;
  display:inline;
  cursor:pointer;
}

.fa-sendchat-circle {
  background-color: #384571 !important;
  color: #384571 !important;
  border-radius: 50%;
  font-size: 32px;
}

.showIcon {
  display: inline-block !important;
}
  
.fa-stack        .fas { 
  color: black; 
  cursor: pointer;
}

.fa-stack        .fab { 
  color: black; 
  cursor: pointer;
}

.fa-stack        .far { 
  color: black; 
  cursor: pointer;
}

.fa-stack        .fas.fa-circle { 
  opacity:1.0; 
  color:white; 
}

.fa-stack:hover  .fas.fa-circle { 
  opacity:1.0; 
  color:lightblue; /* Safari */
  transition-duration: 0.4s;
}

.fa-stack        .fas.fa-square { 
  opacity:1.0; 
  color:white; 
}

.fa-stack:hover  .fas.fa-square { 
  opacity:1.0; 
  color:lightblue; /* Safari */
  transition-duration: 0.4s;
}

.fa-stack:focus  .fas.fa-circle { 
  opacity:1.0; 
  color:red; /* Safari */
  transition-duration: 0.4s;
}

.fa-zoom-text {
  margin-top: .05em;
  z-index: 25;
  cursor: pointer;
}

.fa-zoomdiv-action { /* Safari */
  transition-duration: 0.4s;
  font-size: 18px;  
}

.fa-zoomdiv-action:hover {
  background-color: lightblue;
  border-radius: 50%;
}

.fa-zoomdiv-action:focus {
  background-color: red;
  border-radius: 50%;
}
.zoom-level {
  float: right !important;
  right: 6px !important;
  bottom: 6% !important; 
  z-index: 2 !important;  
}

.zoom-text {
  text-shadow: 2px 2px 2px black;
  z-index: 2;
  color:white;
}

.oneX {
  font-size: 10px;
  display: none;
}

.twoX {
  font-size: 14px;
}

.threeX {
  font-size: 18px;
}

.fourX {
  font-size: 22px;
}

.fiveX {
  font-size: 26px;
}

@media screen and (max-width: 450px) {
  .maxdivcontrol-spacing{
    margin-left: 5px;
  }
  /*TP-2810 -- OT*/
  .shapeselector-spacing{
    margin-bottom: 5px;
  }
  /* TP-2491 & TP-2508
  .shape-selector-ow-left {
    left: 9%;
  }
  .shape-selector-left{
    left: -7.3% ;
  } */
  .sketch-picker{
    right: 33%; /* NS2-523	*/
  }
}

@media screen and (min-width: 451px) {
.maxdivcontrol-spacing{
    margin-left: 3px;
  }
  /*TP-2810 -- OT*/
  .shapeselector-spacing{
    margin-bottom: 4px;
  }
  /* TP-2491 & TP-2508 
  .shape-selector-ow-left {
    left: 9%;
  }
   .shape-selector-left{
    left: -9%;
  } */
  .sketch-picker{
    right: 53%; /* NS2-523	*/
  }
}
@media screen and (max-width: 1299px) {
    .screensizecontrols{
        display: none !important;
    }
}

@media screen and (min-width: 1300px) {
    .screensizecontrols{
    }
    /* TP-2491 & TP-2508
    .shape-selector-ow-left {
      left: 2%; /*TP-2810
    }
     .shape-selector-left{
      left: -7.5%; /*TP-2617
    } */
    .sketch-picker{
      right: 65%; /*TP-2617 & NS2-523	*/
    }
}

.chat-popup {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 25px;
  z-index: 20;
  overflow: auto;
}

.OT_nameEx{
  font-family: 'Titillium Web' !important;
  float: none !important;
  left: 50% !important;  
}

/* Chat containers */
.container {
    border: 1px solid white !important;
    border-radius: 1px;
    padding: 2px !important;
    margin: 2px 0;
    width: 300px;
    height: 400px;
    background-color: transparent;
}


/* Full-width textarea   
  padding: 5px !important;
  margin: 5px 0;
  border: none;
  resize: none !important;
*/


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
}

/* Full-width textarea with focus */
.textareaIM:focus {
  background: #ddd;
  outline: none;
}

.message_in{
  font-family: 'Titillium Web' !important;
  color: rgb(10, 255, 255) !important;
  float: left;
}

.message_out{
  font-family: 'Titillium Web' !important;
  color: rgb(255, 255, 255) !important;
  float: right;
}

/* Clear floats */
.container::after {
    content: "";
    clear: both;
    display: table;
}

/* Style time text */
.time-right {
  float: right;
  font-size: 12px !important;
  font-family: 'Titillium Web' !important;
  color: #e59227 !important;
}

/* Style time text */
.time-left {
  float: left;
  font-family: 'Titillium Web' !important;
  font-size: 12px !important;
  color: #e59227 !important;
}
.photo-viewer-container {
  width: 100% !important;
  /* height: 100% !important; */
  /* min-height: 400px; */
  position: relative;
  /* overflow-y: auto; */
}
.pg-viewer-wrapper {
  /* margin: 0 auto !important; */
  width: 100% !important;
  height: 100% !important;
  min-height: 400px;
  position: relative;
  overflow-y: auto !important;
  /* position: relative !important;
  background: transparent !important;
  justify-content: center !important;
  overflow-x: hidden;
  z-index: 12;
  top: 70px;
  max-height: calc(100% - 100px);
  display: inline-block; */
}
/*TP-130 -- disable the column header onlick events*/
.pg-viewer .react-grid-Header {
  pointer-events: none;
}

/* TP-1115 .pg-viewer-wrapper #docx {
  overflow-y: auto !important;
}

.pg-viewer-wrapper .video-container {
  height: 100% !important;
  object-fit: contain;
} */
.pg-viewer {
  /* width: 100%; */
  height: auto !important;
  min-height: 100% !important;
  width: 100% !important;
}
.OT_name {
  text-align: center;
}

.close-modal {
  position: fixed !important;
  /* padding-left: 5px;  */
  /* top: 1%; */
  /* left: 85%; */
  /* color: red; */
}
#closeAsset > .fa-circle {
  color: red !important;
}

.close-file-icon {
  /*font-size: 28px;
  font-weight: 10000;
  position: absolute; 
  padding-left: 5px; 
  /*top: 13px;
  padding: 0.001rem 0.001rem 5px 0;
  width: 35px; 
  height: 35px;
  right: 2%; 
  color: #fff;
  background-color: red;*/
  z-index: 11;
  /* border-radius: 50%; 
  border:#fff;*/
  color: #fff !important;
}

#closeAsset:hover {
  .fa-circle {
    color:lightblue;

  }
}

.close-file-icon:hover {
  color: #000 !important;
}

.close-file-viewer {
  font-size: 24px; 
  position: absolute; 
  top: 41px; 
  right: 49%; 
  z-index: 11; 
  color: #fff;
}

.file-viewer-container {
  /* width: 80%; */
  height: 100%;
  background: transparent !important;  
  /* margin: 0 auto; */
  z-index: 13;
  display: flex;
  justify-content: center;
}
.timer-span{
  z-index: 1;
  position: absolute;
  top: 4px;
  width: auto;
  right: 5px;
  font-size: 15px !important;
  font-family: 'Titillium Web' !important;
}

.OT_video-poster {
  z-index: inherit !important;
}

a[data-tool-tip]{
    position: relative;
    text-decoration: none;
    color: rgba(255,255,255,0.75);
    font-family: 'FontAwesome' !important;
    z-index: 1;/*12*/
    display: inline-block;
}

a[data-tool-tip]::after{
    content: attr(data-tool-tip);
    display: block;
    position: absolute;
    background-color: rgb(156, 151, 151);
    padding: .25em .5em;
    color: white;
    border-radius: 10px !important;
    top: 0;
    left: 10px;
    white-space: nowrap;
    transform: scale(0);
    transition: 
    transform ease-out 150ms,
    top right ease-out 150ms;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 1.5;    
    font-family: 'Titillium Web' !important;
}

a[data-tool-tip]:hover::after{
    transform: scale(1);
    top: 75%;
}

a.data-top[data-tool-tip]:hover::after {
  transform: scale(1);
  top: -50%;
}

.icon-wrapper {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform;
  /* margin-right: 10px;*/
  font-size: 19px;

}  

.icon-wrapper:hover {
    transform: scale(1.1);  
}

.anchorTagOT[title] {
    font-family: 'Titillium Web' !important;
    font-size: 40px !important;
    border-bottom: 1px dashed;
    text-decoration: none
  }
  
  .anchorTagOT[title]:hover {
    font-family: 'Titillium Web' !important;
    font-size: 100px !important;
    border-bottom: 1px dashed;
    text-decoration: none;
  }
  .showDiv {
    display: block;
  }

  .hideDiv {
    display: none !important;
  }

  #actionRequestControls {
    text-align: center;
    position: absolute !important;    
    z-index: 1060; 
    background-color: transparent !important; 
  }

  #controlpanel {
    bottom: 58px; /*TP-4956*/
    text-align: center;
    position: absolute;
    width: 100%;
    z-index: 1060;
  }

  #recording_button {
    /* bottom: 42px; */
    /* position:absolute; */
    right: 10%;
  }

  #recording_inactive {
    right: 10%;
  }

  #maxdivcontrols {
    z-index: 1060;
  }

  .sketch-picker {
    position: absolute;
    bottom: 140%;
    z-index: 1060;
  }

  .shapeselector {
    /*TP-2810 -- OT*/
    position: absolute;
    bottom: 12%; /*TP-2491*/
    
  }

  .shape-selector {
    position: absolute;    
    bottom: 450%;
    z-index: 1061;
    /*TP-2810 -- OT & TP-2491*/
    transform: rotate(0deg);
  }

  /*TP-2491*/
  .rotate90 {
    /*TP-2810 -- OT*/
    transform: rotate(0deg);
  }

  .zoomSelector {
    position: absolute;    
    bottom: 200%;
    max-height : 20px;
    z-index: 1060;
    background-color: transparent;
    /*overflow: auto;*/
    /* white-space: nowrap; */
  }

  .zoomSelector span > span {
    position: relative;
    display: inline-block;
   text-align: center;
    padding: 14px;
    text-decoration: none;
  }

  .zoomSelector span > span {
    z-index: 1060;
  }

  .arrow-down {
    position: absolute;
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    
    border-top: 20px solid white;
    left: 46%;
    top: 35px;
  }

  .rel {
    position: relative;
  }

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: grey;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tip {
    border-radius: 10px !important;
    /* max-width:50%; */
    /* min-width: 200px !important; */   
    /* text-align: left !important;  */   
    color: white !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 1.5;    
    /* padding: 15px 18px !important; */
    /* border-bottom: 5px solid black; */
    /* box-shadow: 0 0 5px 5px rgba(53, 52, 52, 0.7);   */
}

.tip-logo {
    border-radius: 10px !important;
    /* max-width:50%; */
    min-width: 380px !important;
    text-align: center !important;
    color: white !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 1.5;    
}

.tip-badge {
  border-radius: 10px !important;
  /* box-shadow: 0 0 20px 20px rgba(19, 18, 18, 0.5);  */
}

.tip-max{
    border-radius: 10px !important;      
    color: white !important;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 1.5; 
    /* box-shadow: 0 0 20px 20px rgba(19, 18, 18, 0.5);  */
}



/* NS2-360 */
.Blink {
  animation: flasher 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@keyframes flasher {
  from { color: white; text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);}
  to { color: red; text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.7);} 
}

@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}
/* NS2-367 */
.no-pointer-events{
  pointer-events: none;
}
.disable-image{
  opacity: 0.5;
}

.font-awesome-grey{
color: grey !important;
}

.nameDiv{ 
  text-shadow: 2px 2px 2px black;
  z-index: 2;
}

.rec-button {
  z-index: 20;
  right: 7%;
  /* top: 8%; */
}

.rec {
  /* right: 5.5%; */
  /* top: 9%; */
}

/**TP-3213*/
#sidePanel {
  z-index: 1000; /*TP-4228*/
}
.sidePanel {
  max-height: 0px; /* For Safari 3.0+ */
  transition: max-height 0.2s ease-in 0s; /* Standard syntax */
}

.side-panel-card-shadow {
  box-shadow: 0 10px 10px rgba(255, 253, 253, 0.2);	
}

.side-panel-card {  
  border-radius: 10px !important;
}

.side-panel-card-logo {
  border-style: none !important;
  border-radius: 10px !important;
}

/*TP-3213*/
.nav-buttons-side-panel {
  position: absolute !important;
  top: 45%; 
  background-color: transparent;
  z-index:1060;
}

::-webkit-scrollbar {
  background: transparent; /* make scrollbar transparent */
  /* -webkit-appearance: none; */
  width: 0;
  height: 0;
  /* margin-top: 10px; */
}

.side-panel-container {
  display: flex;
  flex-direction: row;
  width: "100%";
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;  
}

.panel-background {
  background-color: #1d381c33 !important;
}

.grid-card {	
  box-shadow: 0 10px 10px rgba(255, 253, 253, 0.2);	
  border-radius: 10px !important;
}

.grid-card-logo {
  border-style: none !important;
  border-radius: 10px !important;
}

.paddingVdo {
  padding-left: 6px;  
  padding-top: 10px;
}

.marginVdo {
  margin-left: 6px;
  margin-top: 10px;
}

/* MB2-420 */
.video-stream{
  border-radius:10px !important;
}

video.fit-cover {
  object-fit: cover !important;
}

video.fit-contain {
  object-fit: contain !important;
}

video.border {
  border-radius:10px !important;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; */
}

.annotate-class {
  background-color: #242323;
  top: 0 !important;
  opacity: 0.5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.annotate-webrtc {
  background-color: #242323;
  opacity: 0.5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.hide {
  display: none;
}

.hide:hover {
  transition: 100ms;
  display: block;
}

.hover-grid {
  height: 100%; 
  width: 100%;  
}

.hover-grid:hover {
  transition: 500ms;
  height: 103%; 
  width: 101%;
}

.hover-grid:hover + .hide {
  transition: 100ms;
  display: block;
}

.grid-cross-parent {
  /* position: absolute !important;
  left: 42% !important; */
  float: right !important;
  right: 80px !important;
  top: 6% !important; 
}

.web-grid-cross-parent {
  top: 7% !important;
  right: 10% !important;
}

.grid-cross-btn {
  position: absolute !important;
  color:white; 
  z-index: 2;
}

.grid-cross-btn:hover {  
  opacity:1.0; 
  color:lightblue;
  text-shadow: 2px 10px 10px #d4af37;
}

/*TP-2967 -- use case 4*/
@media screen and (min-height: 1000px) {
  .grid-cross-icon:hover {
    transition: 500ms;
    font-size: 25px;
  }
  .grid-icon:hover {
    transition: 500ms;
    font-size:  25px;
  }
} 

.grid-view-controls-parent {
  position: absolute !important;
  left: 0% !important;  
  bottom: 5% !important;
  z-index: 1050; /*TP-2342*/
}

.web-grid-loader-parent {
  color: white;
  bottom: 9% !important;
  z-index: 2;
}

.web-grid-view-controls-parent {
  bottom: 9% !important;
  /*z-index: 1050; 	TP-3937 & TP-2342*/
}

@media screen and (max-width: 450px) {
  .grid-left{
    margin-left: 60%;
  }
}

@media screen and (min-width: 451px) {
  .grid-left{
    margin-left: 60%;
  }
}

@media screen and (min-width: 1000px) {
  .grid-left{
    margin-left: 70%;
  }
}

@media screen and (min-width: 1010px) {
  .grid-left{
    margin-left: 60%;
  }
}

@media screen and (min-width: 1500px) {
  .grid-left{
    margin-left: 75%;
  }
}

@media screen and (min-width: 1900px) {
  .grid-left{
    margin-left: 80%;
  }
}


.grid-view-controls {
  z-index: 20;  
}

.grid-circle:hover {
  transition: 500ms !important;
  font-size:  90px !important;
  opacity:1.0; 
  color:lightblue;
}

.red-shadow {  
}

.grid-view-controls:hover {
  text-shadow: 2px 6px 6px #d4af37;
  /* transition: 500ms !important;
  font-size:  50px !important; */
 /*  opacity:1.0; 
  color:lightblue; */
}

.d-none {
  display: none;
}

.d-blk {
  display: blo;
}

.stop-rc {
  position: absolute !important;
  left: 48.5%;
  z-index: 99999;
  cursor: wait;
  border: none;
  margin: 0px;
  padding: 0px;
  /* width: 100% !important;
  height: 100% !important; */
  top: 10%;
  /* left: 2%; */
}

#stopRC {
  cursor: pointer;
}

.react-tooltip-clickable-link {
  pointer-events: auto !important;
  /* background-color: #4d4a49;
  color: white; */
}

.popover-header {
  text-align: center;
}

.hide {
  display: none;
}

.video-freeze {
  position: absolute !important;
  margin: 0 0;
  z-index: 3; /*TP-4287*/
  border: none;
}

/* .video-freeze:hover + .hide {
  display: block;
} */

.video-icon {
  position: relative;
  margin: 25% 0;
}

.fileBody h3 {
  text-align: right !important;
  text-shadow: 2px 2px 2px white;
}

.middle-row {
  /* height: 12%; */
}

#file-viewer > .modal-content {
  background: rgba(255,255,255,0);
  border: none;
}

.file-name {
  /* top: 7% !important; */
  text-align: center !important;
  font-size: 25px;
  z-index: 3;
  text-shadow: 2px 2px 2px black;
}

#request-dialog {
  z-index: 1070 !important;
  margin-top: 8% !important;
}

/*TP-2491*/
#canvas {
  font-family:FontAwesome;  
}

/*TP-4118 -- Handling of showing/hiding of default cursor in 
Canvas element when Pointer Annotation is either selected or
not selected */
.cursor-cross-hair {
  cursor: crosshair !important;
}

.cursor-none {
  cursor: none !important;
}

/*TP-2720 */
.upper-canvas {
  /*cursor: crosshair !important;*/ 
}

/*TP-2966 -- use case 2*/
.custom-modal-style {
  position: absolute !important;
  top: -47px;
  width: 897px;
  height: 250px;
  z-index: 1070 !important;
}
/*TP-3200*/
.chat-pic{
  border-radius: 50%;
  height: 35px;
  width: 35px;
  object-fit: cover;
  vertical-align: middle;
}
/*TP-3572	& TP-3201*/
.session-profile-pic {
  border-radius: 50% !important;
  object-fit: cover;
}

/*TP-3679 TP-3848
.session-profile-pic-position {
  /* margin-top: 45% !important; */
  /* margin-left: 38.5% !important; 
}*/

.logo-profile-pic {
  height: 50px;
  width: 50px;
  margin-right: 1.5%;
}

.logo-profile-pic-list {
  height: 30px;
  width: 30px;
  margin-right: 1.5%;
}

.profile-cursor {
  cursor: pointer;
}

.high-zIndex {
  z-index:9999 !important;
}

/*TP-4791*/
.zindex-1062 {
  z-index: 1062 !important;
}

/*TP-4572 -- Indicator on Expert/technician when Live video Paused*/
.paused-icon {
  position:absolute !important;
  margin-top: 20%;
  /* margin-left: 37%; TP-4589*/
  z-index: 900;
}

.paused-icon-grid {
  margin-top: 22%;
  margin-left: -55%;
}

.paused-text {
  color: black;
  background-color: #fff;
  font-size: 20px;
  position:absolute;
  margin-top: 38%; /*	TP-4900*/
  /* margin-left: 31%; TP-4589*/
  z-index: 900;
  padding: 0px 10px 3px 10px;
  border-radius: 5px;
  /* text-shadow: 2px 2px 2px black; */
}

/*TP-5551*/
.paused-text-other {
  color: black;
  background-color: #fff;
  font-size: 20px;
  position:absolute;
  /* margin-top: 39%;  *//*	TP-4900*/
  /* margin-left: 31%; TP-4589*/
  z-index: 900;
  padding: 0px 10px 3px 10px;
  border-radius: 5px;
  /* text-shadow: 2px 2px 2px black; */
}

.paused-text-grid {  
  font-size: 18px !important;  /*TP-4589*/ 
}

/*TP-5551*/
.muted-text {
  color: black;
  background-color: #fff;
  font-size: 20px;
  position:absolute;
  /* margin-top: 30%; */ /*TP-5673 TP-4900*/
  z-index: 900;
  padding: 0px 10px 3px 10px;
  border-radius: 5px;
}

.tile-border {
  /* border-style: solid;
  border-color: rgba(249, 208, 4, 0.809);
  border-width: 5px; */
  box-shadow: 0 10px 10px rgba(249, 208, 4, 0.809) !important;
}
.default-blue {
  color: #485890; }

#tooltip, #invite_slider {
  position: absolute;
  left: 50px;
  top: 50px;
  /* font-family: Helvetica Neue,Helvetica,Arial,sans-serif; */
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  display: none;
  z-index: 9999;
  min-width: 280px; }

#tooltip.bottom, #invite_slider.bottom {
  margin-top: 0; }

#tooltip .tooltip-arrow, #invite_slider .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #9c9797; }

#tooltip.bottom .tooltip-arrow, #invite_slider.bottom .tooltip-arrow {
  top: 0;
  left: 2%;
  /* margin-left: -5px; */
  border-width: 0 5px 5px;
  border-bottom-color: #9c9797; }

#tooltip .tooltip-arrow, #invite_slider .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid; }

#tooltip .tooltip-inner, #invite_slider .tooltip-inner {
  min-width: 280px;
  padding: 3px 8px;
  color: white;
  background-color: #9c9797;
  border-radius: 4px; }

#invite_slider a, #tooltip a {
  color: white; }

#tooltip .container-body, #invite_slider .container-body {
  text-align: left;
  text-align: start;
  font-size: 15px; }

#tooltip.on, #invite_slider.on {
  display: block; }

#tooltip.off, #invite_slider.off {
  display: none; }

.tooltip-title-class {
  margin-bottom: 5px;
  line-height: 2.2;
  font-weight: 600;
  text-align: center; }

.tooltip-body-cant {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 3px solid green; }

.fa_custom {
  color: #248C46;
  margin: 10px; }

.fa-asterisk {
  color: white; }

.icon-disabled {
  color: #b77070; }

.heading2 {
    color: #f5f6fa;
    margin-left: 30%;
    padding: 10px 0 0px 10px;
}
div .select-box {
    margin-left: 8%;
    display: flex;
    width: 400px;
    flex-direction: column;
    margin-top: 5%;
    border-color: black;
}
.select-box .options-container {
    background-color: #dfe4ec /* #384571 */;
    color: black;
    max-height: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.4s;
    border-radius: 8px;
    border-color: black;
    overflow: hidden;

    order: 1;
}

.selected {
    background-color: #dfe4ec;
    color: black;
    border-radius: 8px;
    margin-bottom: 8px;
    position: relative;
    order: 0;
}

.selected::after {
    content: "";
    background: url("/Arrow_Down_white.png");
    background-size: 80%;
    background-repeat: no-repeat;

    position: absolute;
    height: 100%;
    width: 32px;
    right: 10px;
    top: 10px;

    transition: all 0.4s;
}

.selected::after:hover {
    content: "";
    background: url("/Arrow_Down_Hover.png");
    background-size: 80%;
    background-repeat: no-repeat;
}

.select-box .options-container.active {
    max-height: 100px;
    opacity: 1;
    overflow-y: auto;
}

.select-box .options-container.active + .selected::after {
    /* transform: ; */
    background: url("/Arrow_Down_Click.png");
    background-size: 80%;
    background-repeat: no-repeat;
    /* transform: rotateX(180d);
    top: px; */
}

.select-box .options-container::-webkit-scrollbar {
    width: 8px;
    background: #0d141f;
    border-radius: 0 8px 8px 0;
}

.select-box .options-container::-webkit-scrollbar-thumb {
    background: #f5f6fa;
    border-radius: 0 8px 8px 0;
}
.select-box .option,.selected {
    padding: 12px;
    cursor: pointer;
}

.select-box .option:hover {
    background: #f5f6fa;
}

.select-box .label {
    cursor: pointer;
}

.select-box .option .selectRadio {
    display: none;
}

.background-modal{
  background-color: white/*  #414fcb */ /* rgb(144,82,218) */;
  /* border-radius: 8px; */
  overflow: hidden;
}

.closeBtnModal {
    background-color: #f5f6fa;
    border-radius: 100%;
}
#slider-range {
  /* height: 400px; */
  border-radius: 15px;
}

.range-box{
	background: rgba(255,255,255,0);
	width: 54px;
	padding-left: 12px;
	/* height: 500px; */
	position: fixed;
  z-index: 1060 !important;
	/* left: 70%; */
	right: 0;
	bottom: 0;
	/* top: 20%; */
	/* margin: auto; */
	border-radius: 10px;
  /* transform: rotate(270deg); */
}
.range-box > h2{
	color: #0d064c;
	margin-bottom: 30px;
}
.range-box > span{
	font-size: 20px;
	margin-right: 10px
}
.noUi-target {
	height: 15px;
	border: none;
	margin: 40px 0 0 0; 
}
@media screen and (max-width: 767px) {
	.noUi-target {
		margin: 40px 0 50px 0; 
	} 
}
.noUi-target .noUi-base {
	background: linear-gradient(117deg,#808080 0,#D3D3D3 100%);
	border: none;
	border-radius: 15px; 
	cursor: pointer;
}
.noUi-target .noUi-base .noUi-connect {
	background: #013a67;
}
.noUi-target .noUi-base .noUi-tooltip {
	/* bottom: -35px; */
	color: #013a67;
	border: none;
	outline: none;
	font-weight: bold; 
}

.noUi-origin {
  transform: translate(0%, 0px);
}

.noUi-tooltip {
  display: none;
}
.noUi-handle.noUi-handle-lower {
	/* background: rgb(48, 46, 46); */
	border-radius: 10px;
	width: 40px;
	height: 40px;
	outline: none;
	right: -5px;
	/* top: -8px; */
	cursor: pointer; 
	box-shadow: none;
	border: 3px solid #ffffff;
transition: all 0.3s ease-in-out;
}
.noUi-vertical {
  width: 30px;
}
.noUi-handle.noUi-handle-lower.noUi-active{
	transform: scale(1.2);
}
.noUi-handle.noUi-handle-lower.noUi-active .noUi-tooltip {
  display: block;
}
.noUi-handle.noUi-handle-lower::before, .noUi-handle.noUi-handle-lower::after {
	display: none; 
}
.noUi-handle.noUi-handle-lower .noUi-touch-area {
	width: 60px;
	height: 60px; 
}

/* .noUi-marker.noUi-marker-vertical, */
.noUi-value.noUi-value-vertical {
  color: black;
  font-weight: 500;
}

.noUi-pips-vertical {
	padding: 0 0 !important;
	left: 15px;
}

.noUi-marker.noUi-marker-vertical {
	background: transparent;
}
.default-blue {
  color: #485890; }

/*************MIXINS*************/
/**********************************/
.signup-popup-wrapper {
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 10px 0; }

.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #485890;
  font-size: 24;
  text-align: left;
  height: 565px;
  max-height: 565px; }

.signup-input-field, .react-datepicker__input-container input, .react-datepicker-wrapper input {
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: none;
  border: 1px solid #ebebeb;
  padding: 10px; }

.signup-input-field:focus, .react-datepicker__input-container input:focus, .react-datepicker-wrapper input:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE; }

.flex-center, .flex-center-wrap {
  display: flex;
  justify-content: center; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.flex-center-wrap {
  flex-wrap: wrap; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-align-center {
  display: flex;
  align-items: center; }

.hidden {
  display: none; }

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform; }

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #777; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #777; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #777; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.relative {
  position: relative; }

.pointer {
  cursor: pointer; }

/*****Validation message styles******/
.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important; }
  .loader div, .loader span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff; }

.loader-text {
  top: 53% !important;
  position: absolute;
  color: #fff; }

.left-loader-multi-word {
  right: 40% !important; }

.left-loader-one-word {
  right: 45% !important; }

.left-loader-two-words {
  right: 43% !important; }

ul {
  list-style: none;
  list-style-type: none; }

label {
  display: initial !important; }

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important; }

.opac-0-7 {
  opacity: 0.7 !important; }

.opac-1 {
  opacity: 1; }

.modal-header {
  font-size: 1.4rem !important; }

.video-container video {
  width: inherit !important; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-5px); }
  60% {
    transform: translateY(0px); } }

.bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom; }

.animate {
  animation-duration: 2s;
  animation-fill-mode: both; }

.btn-g {
  background: #23c97d !important;
  border-color: #23c97d !important; }

.btn-r {
  background: #FF0000 !important;
  border-color: #FF0000 !important; }

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

.margin-bottom-10 {
  margin-bottom: 13px; }

.serial-badge {
  padding: 5px 10px 5px 10px !important;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px !important; }
  .serial-badge i {
    font-size: 20px; }

.close-icon {
  margin-left: 5px; }

.btn-plus {
  display: block;
  height: 35px;
  width: 38px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: #23c97d;
  color: white;
  padding: 0;
  min-width: 0; }

.btn-trial-register {
  width: 100%; }

.input-wrapper {
  position: relative; }

.modal-trial-register .modal-title {
  font-size: 1.25rem;
  text-align: center;
  width: 100%; }

.register-trial-validation-msg {
  color: #FF0000;
  margin-top: 0px;
  position: absolute;
  font-size: 12px;
  right: 0px;
  left: none; }

.react-hint {
  padding: 5px;
  position: absolute;
  z-index: 9999;
  cursor: default;
  animation: .5s fadeIn; }

.react-hint__content {
  padding: 10px;
  border-radius: 5px;
  background: #808080;
  color: #fff; }

.react-hint:after {
  content: '';
  width: 0;
  height: 0;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 5px solid transparent; }

.react-hint--top:after {
  top: auto;
  border-bottom: none;
  border-top-color: #808080; }

.react-hint--left:after {
  left: auto;
  border-right: none;
  border-left-color: #808080; }

.react-hint--right:after {
  right: auto;
  border-left: none;
  border-right-color: #808080; }

.react-hint--bottom:after {
  bottom: auto;
  border-top: none;
  border-bottom-color: #808080; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

title {
  text-transform: lowercase !important; }

body {
  font: 1rem/1.5 var(--font-family-titillium); }

th {
  text-align: left !important; }

table {
  clear: both;
  border-collapse: separate !important;
  border-spacing: 1px;
  width: 100%;
  border-radius: 3px;
  background-color: #1c2238; }

.tm-light {
  font-family: "Titillium Web" !important;
  font-weight: 300 !important; }

.tm-regular, td {
  font-family: "Titillium Web" !important;
  font-weight: 400 !important; }

.tm-semi-bold, th {
  font-family: "Titillium Web" !important;
  font-weight: 700 !important; }

.tm-bold {
  font-family: "Titillium Web" !important;
  font-weight: bolder !important; }

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Titillium Web";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2em; }

h2, .h2 {
  font-size: 1.5em; }

h3, .h3 {
  font-size: 1.17em; }

h4, .h4 {
  font-size: 1.12em; }

h5, .h5 {
  font-size: 0.83em; }

h6, .h6 {
  font-size: 0.75em; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #fff;
  background-color: #384377;
  border: 1px solid #384377; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #724ed3;
  border-color: #724ed3; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #384377;
  border-color: #384377; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #808080; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #808080; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #808080; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #808080; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #808080;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.home {
  min-height: 100vh;
  background-size: cover;
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
  background-position: center; }

.home-background-lg {
  background-image: url(/static/media/New_backgroung_lg.38f76eee.jpg); }

.home-background-md {
  background-image: url(/static/media/New_backgroung_md.64b7dc91.pg); }

.landing {
  background: #1c2238;
  min-height: 100vh;
  position: relative;
  overflow: hidden; }
  .landing .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2; }
    .landing .footer div > a {
      color: white;
      /* padding: 1% 0%; */
      margin-top: 0.5%;
      margin-bottom: 0.5%; }
  @media (max-width: 767.98px) {
    .landing .footer-height {
      height: 30px; }
    .landing .left {
      float: left;
      margin-left: 5%; } }
  @media (min-width: 768px) {
    .landing .footer-height {
      height: 30px; }
    .landing .left {
      margin-left: 0; }
    .landing .middle {
      margin-left: 30%;
      margin-right: 20%; }
    .landing .right {
      float: right;
      margin-right: 30%; } }
  @media (max-width: 991.98px) {
    .landing {
      padding: 40px 0 30px 0; } }
  @media (min-width: 992px) {
    .landing {
      padding: 98px 0 5px 0px; } }

/*TP-3949*/
.landing-my-profile {
  background: #1c2238;
  min-height: 100vh;
  position: relative;
  overflow: hidden; }

/* for webrtc session */
.onprem_landing {
  background: #1c2238;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 0 0 0 0; }
  .onprem_landing .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px; }
    .onprem_landing .footer div > a {
      color: white;
      padding: 1% 0%; }

.third {
  width: 31%; }

.half {
  float: left;
  width: 44%; }

.center {
  display: flex;
  justify-content: center; }

.div-center {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 500px;
  height: 200px; }
  @media screen and (max-width: 600px) {
    .div-center {
      width: 300px; } }

.toast-position {
  position: fixed !important;
  bottom: 10%;
  right: 20px;
  z-index: 1; }

.app-icons {
  padding: 0px 5px; }

.fa-android {
  color: #39ff14; }

.white {
  color: white; }

.fa-apple {
  color: ghostwhite; }

.checkbox, .big-checkbox {
  margin-right: 5px;
  vertical-align: middle; }

.big-checkbox {
  width: 15px;
  height: 15px; }

.internet-status {
  position: fixed;
  width: 100%;
  color: #FFF;
  padding: 0.5em;
  background: red;
  z-index: 4;
  top: 0px; }

.signin-clas {
  display: flex; }

.modal-centered {
  position: absolute;
  top: 30%;
  left: 37%;
  font-size: x-large; }

/* .modal-content {
    height: 300px;
}

.modal-body {
    margin-top: 10%;
} */
.log-me-center-dialog {
  top: 6% !important;
  /* height: 630px; */
  border-radius: 25px;
  margin-top: 1%;
  /* margin-left: 20%;
      margin-right: 20%; */
  padding: 1% 2% 4% 1%; }

.log-me-color {
  background-color: transparent !important; }

.log-me-min-width {
  width: 700px; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.SimpleVideo-loader {
  animation: spin 1s linear infinite;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.8);
  border-left-color: transparent;
  color: transparent;
  content: '';
  display: block;
  height: 54px;
  /* left: $button-size * 0.05; */
  position: absolute;
  top: 50%;
  width: 54px; }

.actions-wrapper {
  width: 100%;
  padding: 30px;
  bottom: 0; }

.fa-sendchat-circle {
  background-color: #384571 !important;
  color: #384571 !important;
  border-radius: 50%;
  font-size: 32px; }

.fa-maxdiv-circle {
  background-color: white;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  font-size: 30px; }

.fa-maxdiv-action {
  color: black;
  /* Safari */
  transition-duration: 0.4s;
  font-size: 20px; }

.fa-maxdiv-action:hover {
  background-color: lightblue;
  border-radius: 50%; }

.fa-maxdiv-action:focus {
  background-color: red;
  border-radius: 50%; }

