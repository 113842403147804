/*TP-3423*/
@import '../../styles/shared'; //TP-3657 

* {
    margin: 0;
    padding: 0;
}

/* To give the containers
   in spherical shape */
.dot {
    border-radius: 5%;
}

.dot span {

    position: absolute;
    display: block;
    border: 5px;
    border-radius: 5%;    

}

.dot {
    .row-div {
        /* animation: animate 2s ease-in; */
    }
}

/*the animation*/
@keyframes animate {
    0% {
        /* transform: translateY(-300px); */
        height: 0px;
        width: 0px;
    }

    50% {
        /* transform: translateY(190px);*/
        width: +200px;
        height: 100px;

    }

    100% {
        /* transform: translateY(-300px); */
        height: 200px;
        width: 400px;
    }
}

/* The bubble is defined with the following
   Set the color, delay and
   duration(i.e different speed) */
 .dot span:nth-child(1) {
    top: 15px;
    left: 50px;
    position: absolute;
    z-index: 9999;
    height: fit-content;
    width: 450px; /*TP-3484*/
    border-radius: 5%;
    background-color: #384571; 
    animation-delay: 0.3s;
    animation-duration: 2.6s;
}

.overflow {
    overflow-x:auto;
    overflow-y: scroll;
}

.row-div {    
    width: 410px;   /*TP-3875*/  
    padding: 15px 10px 15px 10px;
}

.wrap-content {
    word-wrap: break-word;
}
/*TP-3657*/
.Invitee {
    background: #777 !important;
}

.Technician {
    background: #71a95a !important;
}
  
.Expert {
    background: $default-blue !important;
}

.Inactive {
    background: #c94e4a !important;
}

.Admin {
    background: #89783f !important;
}
  
.admin-expert {
    background: #89783f !important;
}

.bubble-user-type-flag {
    width: fit-content !important;
    margin: 10px -50px !important;
    padding: 0px 8px;
    border-radius: 5px !important;
    font-size: 13px;
    font-weight: bold;
}