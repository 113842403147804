.checkbox-span {
    display: inline-block;
    width: 18px;
    height: 16px;
    margin-right: 10px;
    background-image: linear-gradient(white,white);
    margin-bottom: 1px;
    border: 1px solid #999;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    -ms-border-radius: 2px;
    background: #fff;
    background-image: -moz-linear-gradient(white,white);
    background-image: -ms-linear-gradient(white,white);
    background-image: -o-linear-gradient(white,white);
    background-image: -webkit-linear-gradient(white,white);
    background-image: linear-gradient(white,white);
    vertical-align: middle;
    cursor: pointer;
}

.checkbox-span.checked::before {
    display: block;
    line-height: 1.2;
    margin-top: 0px;
    text-align: center;
    content: "\F00C";
    font: normal normal normal 13px/1 FontAwesome;
}

.checkbox-input {
    width: 2em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    display: none;
}