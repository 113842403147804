/* Set height of body and the document to 100% to enable "full page tabs" */
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Titillium Web';
}

/* Style tab links */
.tablink {
  background-color: #1c2238;
  border-spacing: 1;
  border: none;
  color: white;
  float: left;
  outline: none !important;
  cursor: pointer;
  // padding: 14px 16px;
  padding: 7px 8px;
  font-size: 24px;
  // width: 12.0%; //TP-3328,4753
}

.tablink:hover {
  background-color: #2d3858;
  border-spacing: 1;
}

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
  color: white;
  width: 100%;
  display: none;
  overflow-y: auto;
  // padding: 20px 20px 10px 20px;
  padding: 10px 20px 5px 10px;
  // padding: 100px 20px;
  height: 100%;
  background-color: #1c2238;
}

/*TP-3949*/
.min-height-profile {
  min-height: 100vh;
}
