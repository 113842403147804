// @import url('https://fonts.googleapis.com/css?family=Titillium+Web|Titillium+Web:semibold|Titillium+Web:Light|Titillium+Web:Extra-Light');
//@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
//@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/solid.min.css');
// @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css');
/* titillium-web-regular */
@font-face {
    font-family: 'Titillium Web';
    src: url('./fonts/TitilliumWeb-Regular.ttf') format('truetype')
}
// TP-2475
@font-face {
    font-family: 'FontAwesome';
    src: url('./fonts/fontawesome-webfont.ttf');
}

body {
    margin: 0;
    padding: 0;
    // font-family: 'Titillium Web';
    background-size: cover;
}
