/*TP-6222*/
.side-panel-parent {
    position: absolute;
    top: 146px;
    bottom: 120px;
    border-radius: 10px 0px 0px 10px;
    background-color: white;
    width:270px;
}
.panel {
    border-radius: 20px;
    width: 95%;
    margin-left: 5px !important;
}
.items {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 1rem;
    background-color: #1c2238;
    margin-top: 5px;
}
.items:hover {
    background-color: rgb(45, 56, 88);
}
.panel-active {
    background-color: rgb(45, 56, 88);
}

.searchchatbtn {
    background-color: #384571 !important;
    color: white;
    border-radius:50%;
    font-size: 21px;
    margin-left: -40px;
    margin-top: 27px;
    /* bottom: 10px !important; */
     position: absolute;
   vertical-align:-webkit-baseline-middle;
    display:inline;
    cursor:pointer;
  }

  lastseen-timecls {
    color: gray !important;
  }