#closePlayer > .fa-circle {
    color: red !important;
  }
  
  .close-file-icon {
    z-index: 11;
    color: #fff !important;
  }
  
  #closePlayer > .fa-circle:hover {
      color:lightblue;  
  }
  
  .recording-label {
    color: white;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-55%, -50%);
  }

.recording-container {
    position: relative;
    text-align: center;
}

.hide-button {
  opacity: 0;
}

.hide-button:hover {
  transition: 100ms;
  opacity: 1;
}

.video-tile:hover + .hide-button {
  transition: 100ms;
  opacity: 1;
}


.play-button{  
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-55%, -50%);
}

/* .hover-grid:hover {
  transition: 500ms;
  height: 103%; 
  width: 101%;
} */

