
/* Rounded sliders */
#job-wrapper .slider.round {
    border-radius: 34px;
  }
  
  #job-wrapper .slider.round:before {
    border-radius: 50%;
  }
  
  /*Rounder slider end*/

  #job-wrapper .switch {
    position: relative;
    display: inline-block !important;
    width: 50px;
    height: 25px;
    margin-left: 2px;
  }
  
  #job-wrapper .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  #job-wrapper .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  #job-wrapper .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  #job-wrapper input:checked+.slider {
    background-color: #2196F3;
  }
  
  #job-wrapper input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  #job-wrapper input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .pd-1 {
    padding-top: 1%;
  }