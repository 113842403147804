.flash-message {
  position: fixed;
  /* top: 30px;
  left: 40px; */
  z-index: 1060;

  background-color: white;
  padding: 0.5% 1% 0.5% 1%;
  border-radius: 10px;
  border-style: solid black;
  color: rgb(56, 69, 113);;
  font-size: 22px;

  transition: .2s;
  transform: translateY(16px);
  transform-origin: top right;
  // hide element
  opacity: 0;
  visibility: hidden;
}

.flash-message--is-visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/*TP-1518*/
.centered {
  position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
}