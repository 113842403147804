
@import '../../styles/shared';
@import '../../styles/modal';


.formheader {
  h2 {
    text-align: center;
    padding: 1% 2% 0% 1%;
  }
  h4 {
    text-align: center;
    padding: 1% 2% 0% 1%;
  }
  label {
    text-align: center;
    padding: 1% 2% 0% 1%;
  }
  img {
    margin-left: 5%;
  }
}

.pg-viewer-wrapper {
  width: 100%;
    height: 90%;
    border: 0
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formbody {
  color: $default-blue;
  top: 6% !important;
  .input-wrapper {
    margin-bottom: 10px;
    margin-left: 15px;
  }
  .column {
    float: left;
    width: 50%;
    padding: 1% 1% 1% 1%;
  }
  .topSpace {
    top: 20% !important;
  }

  label {
    margin-left: 15px;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
    margin-left: 10px;
  }
}

  .dialog {
    top: 6% !important;
    height: 630px;
    border-radius: 25px;
    margin-top: 1%;
    margin-left: 20%;
    margin-right: 20%;
    padding: 1% 2% 4% 1%;
    background:white;
  }

.margin120{
  margin-top: 120px;
}

.margin65{
  margin-top: 30px;
}

.margin10{
  margin-left: 50px;
}

.labelField{
  padding-left: 6px;
}
.btn-green {
  @include button-mixin();
}
.btn-blue {
  @include button-mixin($background: $dark-blue);
}
.admin-form-validation-msg {
  @include validation-message($right: 0, $margin-top: 0, $font-size: 14px)
}

.email-exists-validation-msg {
  @include validation-message($margin-top: -20px, $font-size: 14px);
}

.error-message {
  @include validation-message($margin-top: 140px, $font-size: 13px, $left: 30%);
}

.button-flex{
  justify-content: space-evenly;
}

.medium-size-button{
  font-size: 25px;
}

.signup-modal-content{
  background-color: transparent !important;
  border: none !important;
}

.signup-modal-header{
  border: none !important;
}

.signup-modal-content .close span{
  color: #ffffff;
}