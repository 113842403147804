#slider-range {
  /* height: 400px; */
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.range-box{
	background: rgba(255,255,255,0);
	width: 54px;
	padding-left: 12px;
	/* height: 500px; */
	position: fixed;
  z-index: 1060 !important;
	/* left: 70%; */
	right: 0;
	bottom: 0;
	/* top: 20%; */
	/* margin: auto; */
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
  /* transform: rotate(270deg); */
}
.range-box > h2{
	color: #0d064c;
	margin-bottom: 30px;
}
.range-box > span{
	font-size: 20px;
	margin-right: 10px
}
.noUi-target {
	height: 15px;
	border: none;
	margin: 40px 0 0 0; 
}
@media screen and (max-width: 767px) {
	.noUi-target {
		margin: 40px 0 50px 0; 
	} 
}
.noUi-target .noUi-base {
	background: linear-gradient(117deg,#808080 0,#D3D3D3 100%);
	border: none;
	border-radius: 15px; 
	cursor: pointer;
}
.noUi-target .noUi-base .noUi-connect {
	background: #013a67;
}
.noUi-target .noUi-base .noUi-tooltip {
	/* bottom: -35px; */
	color: #013a67;
	border: none;
	outline: none;
	font-weight: bold; 
}

.noUi-origin {
  transform: translate(0%, 0px);
}

.noUi-tooltip {
  display: none;
}
.noUi-handle.noUi-handle-lower {
	/* background: rgb(48, 46, 46); */
	border-radius: 10px;
	width: 40px;
	height: 40px;
	outline: none;
	right: -5px;
	/* top: -8px; */
	cursor: pointer; 
	box-shadow: none;
	border: 3px solid #ffffff;
	-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
.noUi-vertical {
  width: 30px;
}
.noUi-handle.noUi-handle-lower.noUi-active{
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-o-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}
.noUi-handle.noUi-handle-lower.noUi-active .noUi-tooltip {
  display: block;
}
.noUi-handle.noUi-handle-lower::before, .noUi-handle.noUi-handle-lower::after {
	display: none; 
}
.noUi-handle.noUi-handle-lower .noUi-touch-area {
	width: 60px;
	height: 60px; 
}

/* .noUi-marker.noUi-marker-vertical, */
.noUi-value.noUi-value-vertical {
  color: black;
  font-weight: 500;
}

.noUi-pips-vertical {
	padding: 0 0 !important;
	left: 15px;
}

.noUi-marker.noUi-marker-vertical {
	background: transparent;
}