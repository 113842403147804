
@import './variables';
/*************MIXINS*************/
@mixin button-mixin(
    $width: auto,
    $height: 50px,
    $display: block,
    $background: $default-green,
    $color: rgb(255, 255, 255),
    $padding: 0 25px 0 25px,
    $min-width: 110px,
    $border-radius: 25px
  ) {
  display: $display;
  height: $height;
  width: $width;
  cursor: pointer;
  border: none;
  border-radius: $border-radius;
  background-color: $background;
  color: $color;
  padding: $padding;
  min-width: $min-width;
}
/**********************************/
.signup-popup-wrapper {
    height: 100vh;
    justify-content: center;
    background-color: rgba(0,0,0, 0.5);
    padding: 10px 0 10px 0;
}
.signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 22px;
    border-radius: 15px;
    background-color: #ffffff;
    color: #485890;
    font-size: 24;
    text-align: left;
    height: 565px;
    max-height: 565px;
}

.signup-input-field{
  background-color: #ebebeb;
  margin-top: 6px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border:none;
  border: 1px solid #ebebeb;
  padding: 10px;
  // margin-bottom: 20px;
}

.signup-input-field:focus,
button[class*='btn']:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-center-wrap {
  @extend .flex-center;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

button[disabled] {
  opacity: 0.75;
  cursor: not-allowed;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #777;
}
input::-moz-placeholder, textarea::-moz-placeholder { /* Firefox 19+ */
  color: #777;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder { /* IE 10+ */
  color: #777;
}
input:-moz-placeholder, textarea:-moz-placeholder { /* Firefox 18- */
  color: #777;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

/*****Validation message styles******/
@mixin validation-message($margin-top: 3px, $font-size: 12px, $right: none, $left: 5px) {
  color: $default-red;
  margin-top: $margin-top;
  position: absolute;
  font-size: $font-size;
  right: $right;
  left: $left;
}

.loader {
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
  pointer-events: all;
  z-index: 99996;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(108, 98, 98, 0.33) !important;
  div, span {
    top: 50%;
    right: 46%;
    position: absolute;
    color: #fff;
  }
}

.loader-text {
  top: 53% !important;  
  position: absolute;
  color: #fff;
}

.left-loader-multi-word {
  right: 40% !important;
}

.left-loader-one-word {
  right: 45% !important;
}

.left-loader-two-words {
  right: 43% !important;
}

ul {
  list-style: none;
  list-style-type: none;
}

label {
  display: initial !important;
}

/** react datepicker style resets**/
.react-datepicker__input-container, .react-datepicker-wrapper {
  display: block !important;
  input {
    @extend .signup-input-field;
  }
}

.opac-0-7 {
  opacity: 0.7 !important;
}

.opac-1 {
  opacity: 1;
}

.modal-header {
  font-size: 1.4rem !important;
}

.video-container {
  video {
    width: inherit !important;
  }
}

@-webkit-keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
  40% {-webkit-transform: translateY(-5px);} 
  60% {-webkit-transform: translateY(0px);} 
} 

@keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-5px);} 
  60% {transform: translateY(0px);} 
}

.bounce { 
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  transform-origin: center bottom;
}

.animate {
  -webkit-animation-duration: 2s;
  animation-duration: 2s; 
  -webkit-animation-fill-mode: both; 
  animation-fill-mode: both; 
}

.btn-g {
  background: $default-green !important;
  border-color: $default-green !important;
}
.btn-r {
  background: $default-red !important;
  border-color: $default-red !important;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}