@import './styles/react-hint';

$font-family-titillium:       'Titillium Web' !default;
$font-family-base:            $font-family-titillium !default;

$h1-font-size:                2em !default;
$h2-font-size:                1.5em !default;
$h3-font-size:                1.17em !default;
$h4-font-size:                1.12em !default;
$h5-font-size:                .83em !default;
$h6-font-size:                .75em !default;

$pagination-active-border-color: #724ed3;
$pagination-active-bg: #724ed3;

$pagination-bg: #384377;
$pagination-border-color: #384377;
$pagination-disabled-bg: #384377;
$pagination-disabled-border-color: #384377;

$pagination-active-color: #fff;
$pagination-color: #fff;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;
$tooltip-bg:                  #808080 !default;

title {
    text-transform: lowercase !important; 
}

body {
    font: 1rem/1.5 var(--font-family-titillium);
}
th {
    text-align: left !important;
    @extend .tm-semi-bold;
}
td {
    @extend .tm-regular;
}

table {
    clear: both;
    border-collapse: separate !important;
    border-spacing: 1px;
    width: 100%;
    border-radius: 3px;
    background-color: #1c2238;
}

.tm-light {
    font-family: $font-family-titillium !important;
    font-weight: $font-weight-light !important;
}
.tm-regular {
    font-family: $font-family-titillium !important;
    font-weight: $font-weight-normal !important;
}
.tm-semi-bold {
    font-family: $font-family-titillium !important;
    font-weight: $font-weight-bold !important;
}
.tm-bold {
    font-family: $font-family-titillium !important;
    font-weight: $font-weight-bolder !important;
}

@import "../node_modules/bootstrap/scss/bootstrap";
