
@import '../../styles/shared';
@import '../../styles/variables';

.btn-register {
  @include button-mixin();
}

.__modal-body {
  color: $default-blue;
  .input-wrapper {
    margin-bottom: 12px;
  }
}
.register-form-validation-msg {
  @include validation-message()
}