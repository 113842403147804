@import '../../styles/shared';
.margin-bottom-10 {
    margin-bottom: 13px;
}
.serial-badge {
    padding: 5px 10px 5px 10px !important;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px !important;
    i {
        font-size: 20px;
    }
}
.close-icon {
    margin-left: 5px;
}

.btn-plus {
    @include button-mixin($background: $default-green,  $height: 35px, $width: 38px, $padding: 0, $min-width: 0, $border-radius: 50%);
}

.btn-trial-register {
    width: 100%;
}

.input-wrapper {
    position: relative;
}

.modal-trial-register {
    .modal-title {
        font-size: 1.25rem;
        text-align: center;
        width: 100%;
    }
}

.register-trial-validation-msg {
    @include validation-message($margin-top: 0px, $font-size: 12px, $right: 0px, $left: none)
}