@import '../../styles/variables';

body {
    margin: 0;
    font-family: 'Titillium Web' !important;
}

#header_bar {
font-family: 'Titillium Web' !important;
height: 98px;
width: 100%;
background-color: #293840;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
position: fixed;
display: -ms-flexbox;
// display: flex;
top: 0;
left: 0;
right: 0;
z-index: 3;
}


#header_bar .left-block {
  display: -ms-flexbox;
  display: flex;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  float: left;
  width: 269px;
  min-width: 269px;
  height: 100%;
  text-align: center;
  border-right: 1px solid #455b66;
}

[header-logo] {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
}

[header-logo="brand_logo"] {
  background-image: url(/white_logo_transparent_background.png);
  width: 164.2px;
  height: 100%;
}

#header_bar .center-block {
float: left;
-ms-flex-positive: 1;
flex-grow: 1;
height: 100%;
display: flex;
padding-left: 30px;
color: white;
font-size: 18px;
}

#header_bar .landing-center-block {
-ms-flex-positive: 1;
flex-grow: 1;
height: 100%;
display: flex;
padding-left: 280px;
color: white;
font-size: 32px;
}

.landing-group-name {
  font-size: 30px;
  word-break: break-all;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 290px;
  color: white;
}

#header_bar .center-block .group-info {
  font-size: 13px;
  font-weight: 600;
  line-height: 0.5;
  letter-spacing: 1.1px;
  color: #7a8b93;
  min-width: 60px;
}

#header_bar .center-block .group-info .group-name {
  font-size: 22px;
  text-overflow: ellipsis;
  height: 26px;
  overflow: hidden;
  white-space: nowrap;
  line-height: 22px;
  margin-bottom: 8px;
}

.group-name {
  font-size: 16px;
  word-break: break-all;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 290px;
  color: white;
}

[header-logo="joinees"] {
  width: 25px;
  height: 26px;
  margin-right: -4px;
  margin-bottom: -1px;
  display: block;
}

#actionBtnDiv{
font-size: 14px;
padding-left: 5px;
display:flex;
}

#joineesCount {
font-size: 14px;
padding-left: 12px;
display:flex;
color: white;
text-align: center;
}

#actionLabel {
font-size: 10px;
padding-left: 5px;
display:flex;
}
#header_bar .right-block {
  display: -ms-flexbox;
  display: flex;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  right: 0;
}

#header_bar .right-block div {
  float: right;
}

#header_bar .muteAll span {
  margin-left: 12px;
  margin-right: 12px;
  vertical-align: top;
  line-height: 36px;
  font-size: 12px;
  color: #c0c0c0;
}

.btn-padding, .btn-no-underline {
  padding: 0 30px;
}

#header_bar .signout-container {
  width: 114px;
  height: 98px;
  border-left: 1px solid #455b66;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  right: 0;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#header_bar .signout-container #signout-icon {
  height: auto;
  width: auto;
  float: none;
  color: white;
  cursor: pointer;
}

.topnav-demo {
  opacity: 0 !important;
  height: 50px !important;
}

.topnav {
font-family: 'Titillium Web';
position: relative;
overflow: hidden;
background-color: #333;
opacity: 0.7;
color: #fff;
}

.topnav a {
font-family: 'Titillium Web';
float: left;
display: block;
color: #f2f2f2;
text-align: center;
padding: 14px 16px;
text-decoration: none;
}

.topnav-centered a {
font-family: 'Titillium Web';
float: none;
position: absolute;
pointer-events: none;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}

.topnav-right a {
font-family: 'Titillium Web';
font-size: 18px;
color: white;
float: right;
cursor: pointer;
}

/* Style buttons */
.btn {
  background-color: DodgerBlue; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  opacity: 0.9;
  cursor: pointer;
}

/* Darker background on mouse-over */
.btn:hover {
  opacity: 0.5;
}

.navbar-gradient{
background-color: #7452cb; /* For browsers that do not support gradients */
background-image: linear-gradient(to left, rgb(144,82,218) 40%, rgb(65,79,203) 60%);
}

.nav-link{
    outline: 0;
    border: none;
    outline-style: none ;
      
  i {
      font-size: 42px !important;
      color: white;
  }
}

.small-nav-link{
    i {
      font-size: 27px !important;
      color: white;
    }
}

.sm-nav-link{
  outline: 0;
  border: none;
  outline-style: none ;
  i {
    font-size: 35px !important;
    margin: 12% 20% !important;
    color: white;
  }
}

.md-nav-link {
  outline: 0;
  border: none;
  outline-style: none ;
  i {
    font-size: 25px !important;
    margin: 8% 20% !important;
    color: white;
  }
}

.xs-nav-link{
  outline: 0;
  border: none;
  outline-style: none ;
  i {
    font-size: 20px !important;
    margin: 4% 20% !important;
    color: white;
  }
}

.d-none {
  display: none;
}

.d-blk {
  display: blo;
}

.font-awesome-red{
color: red !important;
}

.font-awesome-blue{
color: #400088 !important;
}

.font-awesome-green {
  color: #23c97d !important;
}

.signout-no-decoration{
font-size: 25px;
color: #fff !important;
}

@media screen and (max-width: 1024px) {
    .navbar-text{
        width: 650px;
    }
    nav {
      height: inherit;
    }
}

@media screen and (min-width: 1025px) {
    .navbar-text{
        width: 92%; /*TP-3497*/
    }
    nav {
      height: inherit;
    }
}

@media screen and (max-width: 992px) {

    #header_bar{
      height: 40px !important;
    }

    .navbar-left{
        text-align: left !important;
    }

    .navbar-right{
      text-align: right !important;
    }
    
    .nav-item{
        text-align: left !important;
    }
    
    .navbar-text{
        text-align: left !important;
    }

    .participant-count{
        left: 40px  !important;
    }

    .selectBox{
        width: 115px;
    }

    .signout-no-decoration{
      font-size: 20px;
    }  
    nav {
      height: inherit;
    }
    .row-height {
      height:30px;
    }  
}

.participant-count{
    position: absolute;
    left: 57%;
}

.chat-participant-count{
    position: absolute;
    left: 60.5%;//51.5
}

.hr-separator{
    border-left: 1px solid hsl(200, 17%, 88%);
    height: 97px;
    margin: 0;
}

.selectBox{
  
    border-radius:5px;
    border:1px solid #AAAAAA;
    background: rgba(36, 32, 59, 0.3);
    color: #fff;

}
.selectBox option { color: black; }

.no-cursor-pointer{
    pointer-events: none;
}

.faded-image{
    opacity: 0.5;
}

.cursor-pointer {
    cursor: pointer;
}

/*.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}*/

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: grey;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
/* webrtc css*/ 
.member-count{
  left: 95%;
  font-family: 'Titillium Web' !important;
  /* top: 14%; */
}

.chat-count {
  left: 95%;
  font-family: 'Titillium Web' !important;
  /* top: 14%; */
}
.nav-link{
  .rtcIcon{
    font-size: 27px !important;
  }
}
/* webrtc side bar gradient */
.sidebar-gradient{
  background-color: #7452cb; /* For browsers that do not support gradients */
  background-image: linear-gradient(rgb(144,82,218) 40%, rgb(65,79,203) 60%);
}

.high-zindex {
  z-index: 1060;
}

.left-Icons {
    margin-left: 10px;
}

.sideBar-icons {    
    opacity: 1;
}

.sideBar-icons:hover {
    opacity: 0.6;
}

.remove-dots {
  outline:none
}

.cursor-pointer {
  cursor: pointer !important;
}

.icon-disabled {
  color: $disablePinkGrey !important;
}
  
.df-cursor{
  cursor: default !important;
}

#joineesTooltip {
  z-index: 2 !important;
}

.padding-left-7{
  padding-left: 7px;
}

.padding-left-5{
  padding-left: 5px;
}
/*TP-3199*/
.profile-pic{
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-right: 1.5%;
  cursor: pointer;
  object-fit: cover;
  /* float: right; */
}

.profile-pic-sm {
  height: 35px;
  width: 35px;
  margin-top: -5px;
  border-radius: 50%;
  margin-right: 1.5%;
  cursor: pointer;
  object-fit: cover;
}

.user-name {
  margin-right: 3%;
  width: 290px;
  float: right !important;
}

.photo-modal-body {
  /* margin-left: 12%; */
}

.photo-modal-footer {
  margin-top: 5%;
}

.left-button {
  margin-left: 20%;
}

.right-button {
  margin-left: 15%;
}

.scale-div {
  input{
    margin-left: 10px 
  }
}

.margin-top-15 {
  margin-top: 5px;
}

.name-class{
  padding-left: 10px;
}
