div.close-square {
  border: solid 21px;
  color:white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  left: 50%;
}
.close3d {
  width: 25px;
  height: 25px;
  opacity: 0.3;
  
}
.close3d:hover {
  opacity: 1;
  border-radius: 50%;
}
.close3d:before, .close3d:after {
  position: absolute;
  top: -13px;
  left: 0px;
  content: ' ';
  height: 28px;
  width: 2px;
  background-color: black;
}
.close3d:before {
  transform: rotate(45deg);
}
.close3d:after {
  transform: rotate(-45deg);
}