@import '../../styles/variables';

/* --------------- */	

#subscription-table .plan {
    font: 13px;     
    background: #fff;      
    border: 1px solid #ddd;
    color: #333;
    padding: 20px;
    width: 30%;
    float: left;
    _display: inline; /* IE6 double margin fix */
    position: relative;
    margin: 0 5px;
    box-shadow: 0 2px 2px -1px rgba(0,0,0,.3);	
    border-radius: 15px;
    margin-bottom: 10px;
    flex-basis: 300px;
}

#subscription-table .plan:after {
    z-index: -1; 
    position: absolute; 
    content: "";
    bottom: 10px;
    right: 4px;
    width: 70%; 
    top: 80%; 
    box-shadow: 0 12px 5px rgba(0, 0, 0, .3);
    transform: rotate(3deg);	
}	

#subscription-table .popular-plan {
    top: -20px;
    padding: 40px 20px;   
}

/* --------------- */

#subscription-table {
    margin: 0 auto;
    text-align: center;
    width: 90%; /* total computed width */
    zoom: 1;
}

#subscription-table:before, #subscription-table:after {
    content: "";
    display: table
}

#subscription-table .hide {
    content: "";
    display: none
}

#subscription-table:after {
    clear: both
}

/* --------------- */	

#subscription-table .header {
    position: relative;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 40px;
    margin: -20px -20px 20px -20px;
    border-bottom: 8px solid;
}

#subscription-table .header:after {
    position: absolute;
    bottom: -8px; left: 0;
    height: 30px; width: 100%;
    content: '';
    background-color: #026873;
    background-image: linear-gradient(90deg, rgba(255,255,255,.07) 50%, transparent 50%),
    linear-gradient(90deg, rgba(255,255,255,.13) 50%, transparent 50%),
    linear-gradient(90deg, transparent 50%, rgba(255,255,255,.17) 50%),
    linear-gradient(90deg, transparent 50%, rgba(255,255,255,.19) 50%);
    background-size: 13px, 29px, 37px, 53px;
}

#subscription-table .popular-plan .header {
    margin-top: -40px;
    padding-top: 60px;		
}

/* --------------- */

#subscription-table .price{
    font-size: 20px;
}

#subscription-table .monthly{
    font-size: 13px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #999;
    margin-top: 10px;
}

/* --------------- */

#subscription-table ul {
    margin: 20px 0;
    padding: 0;
    list-style: none;
}

#subscription-table li {
    padding: 10px 0;
}

/* --------------- */

.active-plan {
    background: $default-blue;
    width: 100%;
    padding: 11px;
    color: #fff;
    border-radius: 25px;
    i {
        margin-right: 10px;
    }
}