/*TP-6222*/
.chatwindownew {
    background-color: white !important;
    display: flex;
    position: fixed;
    border-color: white;
    z-index: 1050;
    right: 33% !important;
    bottom: 10px;
    border-radius: 0px 10px 10px 0px;
  }

  .chattopsection {
    height: 100px;
  }

  .profile-image {
    margin: 5px 10px;
  }

  .topsectionleft {
    margin: 5px 10px;    
  }

  .chattopicon {
    color: black;
    padding: 10px 5px;
    margin: 10px;    
  }

  .chattopicon .fa-video {
    font-size: 30px;
    margin: 0px 55px;
  }
  
  .chattopicon .fa-phone {
    font-size: 30px;
    margin: 0 20px;
  }

  .topname {
    color: #384571;
    font-size: 23px;
    padding: 7px 0;
    margin: 10px;
  }

  .topname .fa-asterisk {
    /* color: #384571; */
  }

  .badge-type {
    margin-left: 10px;
  }

  .chattop-seperator {
    background-color: #384571;
    width: 95%;
    margin: 10px 10px;
    box-shadow: 2px 2px 2px #384571;
  }
  
  .chatreadonlynew {
    background-color: white !important;
    display: none;
    position: fixed;
    width: 100%;
    height: 90%;
    overflow: auto;
    border-radius: 10px 10px 0px 0px;
  }
  
  .chatInputBar{
    bottom: 10px;
    position: absolute;
    display: none;
    width: 100%;    
  }
  
  .chatdivblob{
    background-color: transparent;
    /* cursor: none; */
    font-size: 15px;
    width: 100%;
    height: auto;
  }
  
  .chatmsgblob{
    background-color: rgb(56, 69, 113); /*#2d3858;*/
    color: white;
    text-align: left;
    /*border-radius: 25px;
    cursor: none; */
    font-size: 16px;
    position: absolute;
    width: 85% !important;
    height: auto;
    font-family: 'Titillium Web' !important;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* margin-left: 5px; */
    position: relative;
    display: inline;
  }
  
  .chatmsgblobright {
    border-radius: 0 25px 25px 25px !important;
  }

  .chatmsgblobleft {
    border-radius: 25px 0 25px 25px !important;
  }
  .chatmsgmultilineblob{
    background-color: rgb(56, 69, 113); /*#2d3858;*/
    color: white;
    text-align: left;
    /* border-radius: 25px;
    cursor: none; */
    font-size: 16px;
    position: absolute;
    width: 85% !important;
    height: auto;
    font-family: 'Titillium Web' !important;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 2px;
    margin-left: 20px;
    word-wrap: normal;
    position: relative;
  }
  .initialblob {  
    text-align: center;
    position: absolute;
    width: 100%;
    background-color: #23c97d;
    color: white;
    border-radius: 50%;
    /* cursor: none; */  
    text-align: center;
    padding: 3px 0;
  }
  
  /*TP-3200*/
  .chatidnewblob {
    color: white;
    border-radius: 50%;
    /* cursor: none; */
    font-size: 18px;
    width: 32px;
    height: 32px;
    text-align: center;
    padding: 3px 0;
    display: inline;
  }
  
  .chatidblob{
    background-color: #7452cb;
    color: white;
    border-radius: 50%;
    /* cursor: none; */
    font-size: 18px;
    width: 32px;
    height: 32px;
    text-align: center;
    padding: 3px 0;
    display: inline;
  }
  /*TP-3200*/
  .idnewright {
    float: left;
  }
  
  .idright{
    float: left;/*right;*/
    background-color: #1fd17e;
  }
  
  /*TP-3200*/
  .idnewleft {
    float: left;
  }
  
  .idleft{
    float: left;
    background-color: #7452cb;
  }
  
  .chatspangap{
    background-color: transparent;
    float: left ;
    width: 100%;
    height: 5px;
    font-family: 'Titillium Web' !important;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  
  .chattimeblob{
    background-color: transparent;
    color:  /* white; */ rgb(56, 69, 113) !important; /*#384571;*/
    /* text-shadow: 2px 2px 2px black; */
    border-radius: 10%;
    /* float: left ; */
    width: 100%;
    height: 20px;
    font-family: 'Titillium Web' !important;
    /* text-align: center; */
    margin-top: 8px;
    margin-bottom: 5px;
  }

  .chattimerightblob{
    text-align: right;
    padding-right: 15%;
  }

  .chattimeleftblob{
    text-align: left;
    padding-left: 8%;
  }

  .chatreplyblob {
    border-color: white;
    border-radius: 10px 10px 0 0;
    /* background: white !important; */
    
    /* text-shadow: 2px 2px 2px #384571; */
    width: 100%;
    /* height: 50px;  */   
    font-family: 'Titillium Web' !important;
    text-align: left;
    margin: 0 0 0 0px;
    /* padding: 0 15px; */
  }

  .divider-cls {
    background-color: white;
    width: 98%;
    margin: 8px 0;
  }

  .chatreplyblobmessage {
    color: #09ebab;
  }

  .chatReplyBubble {
    border-color: white;
    border-radius: 10px 10px 0 0;
    background: white !important;
    
    /* text-shadow: 2px 2px 2px #384571; */
    width: 99%;
    /* height: 50px;  */   
    font-family: 'Titillium Web' !important;
    text-align: left;
    margin-top: 8px;
    padding: 0 15px;
    /* margin-bottom: 5px; */
  }

  .replyclosebtn {
    color: black;
    float: right;
    padding: 5px 15px;
    cursor: pointer;
  }

  .chatreplyspanname {
    color: red;
  }

  .chatreplyspanmessage {
    color: blue;
  }
  
  .textareaIM {
    background-color: rgb(56, 69, 113) !important;
    bottom: 5px !important;
    border: none;
    color: white;
    font-family: 'Titillium Web' !important;
    height: 40px;
    width: 99%;
    float: left !important;
    left: 5px !important;
    padding-left: 5px;
    display:inline;
    padding-right: 40px;
    border-radius: 15px;
    margin-left: 2.5px;
  }

  .chat-search-box {
    background-color: rgb(56, 69, 113) !important;
    bottom: 5px !important;
    border: none;
    color: white;
    font-family: 'Titillium Web' !important;
    height: 45px;
    width: 95%;
    float: left !important;
    left: 5px !important;
    padding-left: 12px;
    display:inline;
    padding-right: 40px;
    border-radius: 15px;
    margin-left: 3.5px;
    margin-top: 1rem;
  }
  
  
  .sendchatbtn {
    background-color: #384571 !important;
    color: white;
    border-radius:50%;
    font-size: 21px;
    margin-left: -40px;
    /* margin-top: 10px; */
    bottom: 10px !important;
     position: absolute;
   vertical-align:-webkit-baseline-middle;
    display:inline;
    cursor:pointer;
  }
  
  .fa-sendchat-circle {
    background-color: #384571 !important;
    color: #384571 !important;
    border-radius: 50%;
    font-size: 32px;
  }

  .bubble {
    height: 50px;
    width:200px;
    top: 15px;
    left: 200px;
    position: absolute;
    z-index: 9999;
    /* height: fit-content; */
    /*width: 450px; TP-3484*/
    border-radius: 10px;
    background-color:#7452cb; /* #384571; */ 
    animation-delay: 0.3s;
    animation-duration: 2.6s;
  }

  .bubble span {
    padding: 10px 10px 10px 20px;
  }

  .bubble-left span {
    padding: 10px 10px 10px 50px !important;
  }

  .deletedblog {
    background-color: gray;
    color: black;
    font-style: italic;
  }

  .edited-section {
    color: gray;
    font-style: italic;
    float: right;
  }

  .chat-pic-new{
    border-radius: 50%;
    height: 35px;
    width: 35px;
    object-fit: cover;
    vertical-align: middle;
    margin-top: -35px;
  }
  