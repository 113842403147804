// @import '../../styles/shared';
@import '../../styles/variables';

.__modal-body {

    .input-wrapper {
      margin-bottom: 35px;
    }
    ul > li {
        margin-left: 5px !important;
    }
}

.fa-icons {
    font-size: 18px;
    font-weight: 1000;
    margin-left: 0;
    padding-left: 0;
    padding-right: 5px;
}

.check {
    color: $default-green;
}

.times {
    color: red;
}

.collapse-text ul{
    margin: 18px !important;
    padding: 0 !important;
}
/* .collapse-text ol {
    margin: 0 !important;
} */

.collapse {
    cursor: pointer;
}

#ellipsis-ex {
    cursor: pointer;
}
.text-truncate {    
    display: inline-block;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


