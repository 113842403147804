$button-size: 60px;
@keyframes spin {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}

.SimpleVideo-loader {
  animation: spin 1s linear infinite;
  border-radius: 50%;
  border: $button-size * 0.05 solid rgba(255, 255, 255, 0.8);
  border-left-color: transparent;
  color: transparent;
  content: '';
  display: block;
  height: $button-size * 0.9;
  /* left: $button-size * 0.05; */
  position: absolute;
  top: 50%;
  width: $button-size * 0.9;
}