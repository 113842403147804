$default-blue: #485890;
$default-green: rgb(35, 201, 125);
$dark-blue: #2a377f;
$loginHeaderTextColor: #cc680d;
$loginTextColor: #fff;
$loginBoxBackgroundColor: transparent;
$userIconColor: #000;
$inputTxtColorLoginBox:#000;
$inputLabelColorLoginBox: #000;
$forgotPasswordColor: #cc680d;
$userGridTopBarBackground: linear-gradient(to left, rgb(144,82,218) 40%, rgb(65,79,203) 60%);
$userGridTopBarColor: #000;
$userGridIconColor: #384571;
$primaryButtonColor: #23c973;
$primaryButtonBorder: #23c973;
$tabsColor: #fff;
$tabsHover: #7452cb;
$navTabsBorderLine:5px solid #384571;
$disabledBackgroundColor: #eaeaea;
$disabledFontColor: #908d8d;
$chatSentByMobileColor: #e8d609;
$chatSentByMobileFont: 16px;
$chatSentByNameColor:#e8d609;
$chatSentByNameFont:14px;
$chatSentByMeColor:#fff;
$chatSentByMeFont:16px;
$mentorLandingBackgroundColor:#1c2238;
$mentorLandingTableBackgroundColor:#1c2238;
$allChatBackground: #0009;
$allChatFootBackground:#384571;
$allChatFooterTextColor:#fff;
$errorMsgColor: red;
$ccmuteColor:#420909;
$unmuteIconColor:#f05b1c;
$default-red: #FF0000;
$disablePinkGrey: rgb(183, 112, 112);

.default-blue {
    color: $default-blue;
}