/* @import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

body, html {
    background-color: black;
    height: 100%;
  font-family: 'Titillium Web';
} */

/* .flex-container > div {
  background-color: #000000;
  border-color: black;
} */

.OT_widget-container{
  border-radius: 10px;
}

.OT_subscriber.OT_video-element{
  border-radius: 10px;
}

.OT_widget-container.OT_video-poster {
  border-radius: 10px;
}

.OT_bar.OT_edge-bar-item.OT_mode-on{
  border-radius: 10px 10px 0px 0px;
}

#publisherContainer, #expertscreenshare {
  margin: auto;
  /* width: 50%; */
  /* border: 3px solid green;
  padding: 10px; */
}

.chatwindow {
  background-color: #1c223833 !important;
  display: flex;
  position: fixed;
  border-color: white;
  z-index: 1070;
  right: 10px;
  bottom: 10px;
}

.chatreadonly {
  background-color: #1c223833 !important;
  display: none;
  position: fixed;
  width: 100%;
  height: 90%;
  overflow: auto;
}

.chatInputBar{
  bottom: 10px;
  position: absolute;
  display: none;
  width: 100%;
  height: 40px;
}

.chatdivblob{
  background-color: transparent;
  /* cursor: none; */
  font-size: 15px;
  width: 100%;
  height: auto;
}

.chatmsgblob{
  background-color: rgb(56, 69, 113); /*#2d3858;*/
  color: white;
  text-align: left;
  border-radius: 25px;
  /* cursor: none; */
  font-size: 16px;
  position: absolute;
  width: 85% !important;
  height: auto;
  font-family: 'Titillium Web' !important;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* margin-left: 5px; */
  position: relative;
  display: inline;
}


.chatmsgmultilineblob{
  background-color: rgb(56, 69, 113); /*#2d3858;*/
  color: white;
  text-align: left;
  border-radius: 25px;
  /* cursor: none; */
  font-size: 16px;
  position: absolute;
  width: 85% !important;
  height: auto;
  font-family: 'Titillium Web' !important;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 2px;
  margin-left: 20px;
  word-wrap: normal;
  position: relative;
}
.initialblob {  
  text-align: center;
  position: absolute;
  width: 100%;
  background-color: #23c97d;
  color: white;
  border-radius: 50%;
  /* cursor: none; */  
  text-align: center;
  padding: 3px 0;
}

/*TP-3200*/
.chatidnewblob {
  color: white;
  border-radius: 50%;
  /* cursor: none; */
  font-size: 18px;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 3px 0;
  display: inline;
}

.chatidblob{
  background-color: #7452cb;
  color: white;
  border-radius: 50%;
  /* cursor: none; */
  font-size: 18px;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 3px 0;
  display: inline;
}
/*TP-3200*/
.idnewright {
  float: left;
}

.idright{
  float: left;/*right;*/
  background-color: #1fd17e;
}

/*TP-3200*/
.idnewleft {
  float: left;
}

.idleft{
  float: left;
  background-color: #7452cb;
}

.chatspangap{
  background-color: transparent;
  float: left ;
  width: 100%;
  height: 5px;
  font-family: 'Titillium Web' !important;
  margin-top: 2px;
  margin-bottom: 2px;
}

.chattimeblob{
  background-color: transparent;
  color:  white !important; /* rgb(56, 69, 113) */; /*#384571;*/
  text-shadow: 2px 2px 2px black;
  border-radius: 10%;
  float: left ;
  width: 100%;
  height: 20px;
  font-family: 'Titillium Web' !important;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 5px;
}

.textareaIM {
  background-color: rgb(56, 69, 113) !important;
  bottom: 5px !important;
  border: none;
  color: white;
  font-family: 'Titillium Web' !important;
  height: 40px;
  width: 99%;
  float: left !important;
  left: 5px !important;
  padding-left: 5px;
  display:inline;
  padding-right: 40px;
}


.sendchatbtn {
  background-color: #384571 !important;
  color: white;
  border-radius:50%;
  font-size: 21px;
  margin-left: -40px;
  /* margin-top: 10px; */
  bottom: 10px !important;
   position: absolute;
 vertical-align:-webkit-baseline-middle;
  display:inline;
  cursor:pointer;
}

.fa-sendchat-circle {
  background-color: #384571 !important;
  color: #384571 !important;
  border-radius: 50%;
  font-size: 32px;
}

.showIcon {
  display: inline-block !important;
}
  
.fa-stack        .fas { 
  color: black; 
  cursor: pointer;
}

.fa-stack        .fab { 
  color: black; 
  cursor: pointer;
}

.fa-stack        .far { 
  color: black; 
  cursor: pointer;
}

.fa-stack        .fas.fa-circle { 
  opacity:1.0; 
  color:white; 
}

.fa-stack:hover  .fas.fa-circle { 
  opacity:1.0; 
  color:lightblue; 
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.fa-stack        .fas.fa-square { 
  opacity:1.0; 
  color:white; 
}

.fa-stack:hover  .fas.fa-square { 
  opacity:1.0; 
  color:lightblue; 
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.fa-stack:focus  .fas.fa-circle { 
  opacity:1.0; 
  color:red; 
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.fa-zoom-text {
  margin-top: .05em;
  z-index: 25;
  cursor: pointer;
}

.fa-zoomdiv-action {
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  font-size: 18px;  
}

.fa-zoomdiv-action:hover {
  background-color: lightblue;
  border-radius: 50%;
}

.fa-zoomdiv-action:focus {
  background-color: red;
  border-radius: 50%;
}
.zoom-level {
  float: right !important;
  right: 6px !important;
  bottom: 6% !important; 
  z-index: 2 !important;  
}

.zoom-text {
  text-shadow: 2px 2px 2px black;
  z-index: 2;
  color:white;
}

.oneX {
  font-size: 10px;
  display: none;
}

.twoX {
  font-size: 14px;
}

.threeX {
  font-size: 18px;
}

.fourX {
  font-size: 22px;
}

.fiveX {
  font-size: 26px;
}

@media screen and (max-width: 450px) {
  .maxdivcontrol-spacing{
    margin-left: 5px;
  }
  /*TP-2810 -- OT*/
  .shapeselector-spacing{
    margin-bottom: 5px;
  }
  /* TP-2491 & TP-2508
  .shape-selector-ow-left {
    left: 9%;
  }
  .shape-selector-left{
    left: -7.3% ;
  } */
  .sketch-picker{
    right: 33%; /* NS2-523	*/
  }
}

@media screen and (min-width: 451px) {
.maxdivcontrol-spacing{
    margin-left: 3px;
  }
  /*TP-2810 -- OT*/
  .shapeselector-spacing{
    margin-bottom: 4px;
  }
  /* TP-2491 & TP-2508 
  .shape-selector-ow-left {
    left: 9%;
  }
   .shape-selector-left{
    left: -9%;
  } */
  .sketch-picker{
    right: 53%; /* NS2-523	*/
  }
}
@media screen and (max-width: 1299px) {
    .screensizecontrols{
        display: none !important;
    }
}

@media screen and (min-width: 1300px) {
    .screensizecontrols{
    }
    /* TP-2491 & TP-2508
    .shape-selector-ow-left {
      left: 2%; /*TP-2810
    }
     .shape-selector-left{
      left: -7.5%; /*TP-2617
    } */
    .sketch-picker{
      right: 65%; /*TP-2617 & NS2-523	*/
    }
}

.chat-popup {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 25px;
  z-index: 20;
  overflow: auto;
}

.OT_nameEx{
  font-family: 'Titillium Web' !important;
  float: none !important;
  left: 50% !important;  
}

/* Chat containers */
.container {
    border: 1px solid white !important;
    border-radius: 1px;
    padding: 2px !important;
    margin: 2px 0;
    width: 300px;
    height: 400px;
    background-color: transparent;
}


/* Full-width textarea   
  padding: 5px !important;
  margin: 5px 0;
  border: none;
  resize: none !important;
*/


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
}

/* Full-width textarea with focus */
.textareaIM:focus {
  background: #ddd;
  outline: none;
}

.message_in{
  font-family: 'Titillium Web' !important;
  color: rgb(10, 255, 255) !important;
  float: left;
}

.message_out{
  font-family: 'Titillium Web' !important;
  color: rgb(255, 255, 255) !important;
  float: right;
}

/* Clear floats */
.container::after {
    content: "";
    clear: both;
    display: table;
}

/* Style time text */
.time-right {
  float: right;
  font-size: 12px !important;
  font-family: 'Titillium Web' !important;
  color: #e59227 !important;
}

/* Style time text */
.time-left {
  float: left;
  font-family: 'Titillium Web' !important;
  font-size: 12px !important;
  color: #e59227 !important;
}
.photo-viewer-container {
  width: 100% !important;
  /* height: 100% !important; */
  /* min-height: 400px; */
  position: relative;
  /* overflow-y: auto; */
}
.pg-viewer-wrapper {
  /* margin: 0 auto !important; */
  width: 100% !important;
  height: 100% !important;
  min-height: 400px;
  position: relative;
  overflow-y: auto !important;
  /* position: relative !important;
  background: transparent !important;
  justify-content: center !important;
  overflow-x: hidden;
  z-index: 12;
  top: 70px;
  max-height: calc(100% - 100px);
  display: inline-block; */
}
/*TP-130 -- disable the column header onlick events*/
.pg-viewer .react-grid-Header {
  pointer-events: none;
}

/* TP-1115 .pg-viewer-wrapper #docx {
  overflow-y: auto !important;
}

.pg-viewer-wrapper .video-container {
  height: 100% !important;
  object-fit: contain;
} */
.pg-viewer {
  /* width: 100%; */
  height: auto !important;
  min-height: 100% !important;
  width: 100% !important;
}
.OT_name {
  text-align: center;
}

.close-modal {
  position: fixed !important;
  /* padding-left: 5px;  */
  /* top: 1%; */
  /* left: 85%; */
  /* color: red; */
}
#closeAsset > .fa-circle {
  color: red !important;
}

.close-file-icon {
  /*font-size: 28px;
  font-weight: 10000;
  position: absolute; 
  padding-left: 5px; 
  /*top: 13px;
  padding: 0.001rem 0.001rem 5px 0;
  width: 35px; 
  height: 35px;
  right: 2%; 
  color: #fff;
  background-color: red;*/
  z-index: 11;
  /* border-radius: 50%; 
  border:#fff;*/
  color: #fff !important;
}

#closeAsset:hover {
  .fa-circle {
    color:lightblue;

  }
}

.close-file-icon:hover {
  color: #000 !important;
}

.close-file-viewer {
  font-size: 24px; 
  position: absolute; 
  top: 41px; 
  right: 49%; 
  z-index: 11; 
  color: #fff;
}

.file-viewer-container {
  /* width: 80%; */
  height: 100%;
  background: transparent !important;  
  /* margin: 0 auto; */
  z-index: 13;
  display: flex;
  justify-content: center;
}
.timer-span{
  z-index: 1;
  position: absolute;
  top: 4px;
  width: auto;
  right: 5px;
  font-size: 15px !important;
  font-family: 'Titillium Web' !important;
}

.OT_video-poster {
  z-index: inherit !important;
}

a[data-tool-tip]{
    position: relative;
    text-decoration: none;
    color: rgba(255,255,255,0.75);
    font-family: 'FontAwesome' !important;
    z-index: 1;/*12*/
    display: inline-block;
}

a[data-tool-tip]::after{
    content: attr(data-tool-tip);
    display: block;
    position: absolute;
    background-color: rgb(156, 151, 151);
    padding: .25em .5em;
    color: white;
    border-radius: 10px !important;
    top: 0;
    left: 10px;
    white-space: nowrap;
    transform: scale(0);
    transition: 
    transform ease-out 150ms,
    top right ease-out 150ms;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 1.5;    
    font-family: 'Titillium Web' !important;
}

a[data-tool-tip]:hover::after{
    transform: scale(1);
    top: 75%;
}

a.data-top[data-tool-tip]:hover::after {
  transform: scale(1);
  top: -50%;
}

.icon-wrapper {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  /* margin-right: 10px;*/
  font-size: 19px;

}  

.icon-wrapper:hover {
    transform: scale(1.1);  
}

.anchorTagOT[title] {
    font-family: 'Titillium Web' !important;
    font-size: 40px !important;
    border-bottom: 1px dashed;
    text-decoration: none
  }
  
  .anchorTagOT[title]:hover {
    font-family: 'Titillium Web' !important;
    font-size: 100px !important;
    border-bottom: 1px dashed;
    text-decoration: none;
  }
  .showDiv {
    display: block;
  }

  .hideDiv {
    display: none !important;
  }

  #actionRequestControls {
    text-align: center;
    position: absolute !important;    
    z-index: 1060; 
    background-color: transparent !important; 
  }

  #controlpanel {
    bottom: 58px; /*TP-4956*/
    text-align: center;
    position: absolute;
    width: 100%;
    z-index: 1060;
  }

  #recording_button {
    /* bottom: 42px; */
    /* position:absolute; */
    right: 10%;
  }

  #recording_inactive {
    right: 10%;
  }

  #maxdivcontrols {
    z-index: 1060;
  }

  .sketch-picker {
    position: absolute;
    bottom: 140%;
    z-index: 1060;
  }

  .shapeselector {
    /*TP-2810 -- OT*/
    position: absolute;
    bottom: 12%; /*TP-2491*/
    
  }

  .shape-selector {
    position: absolute;    
    bottom: 450%;
    z-index: 1061;
    /*TP-2810 -- OT & TP-2491*/
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  /*TP-2491*/
  .rotate90 {
    /*TP-2810 -- OT*/
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .zoomSelector {
    position: absolute;    
    bottom: 200%;
    max-height : 20px;
    z-index: 1060;
    background-color: transparent;
    /*overflow: auto;*/
    /* white-space: nowrap; */
  }

  .zoomSelector span > span {
    position: relative;
    display: inline-block;
   text-align: center;
    padding: 14px;
    text-decoration: none;
  }

  .zoomSelector span > span {
    z-index: 1060;
  }

  .arrow-down {
    position: absolute;
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    
    border-top: 20px solid white;
    left: 46%;
    top: 35px;
  }

  .rel {
    position: relative;
  }

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: grey;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tip {
    border-radius: 10px !important;
    /* max-width:50%; */
    /* min-width: 200px !important; */   
    /* text-align: left !important;  */   
    color: white !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 1.5;    
    /* padding: 15px 18px !important; */
    /* border-bottom: 5px solid black; */
    /* box-shadow: 0 0 5px 5px rgba(53, 52, 52, 0.7);   */
}

.tip-logo {
    border-radius: 10px !important;
    /* max-width:50%; */
    min-width: 380px !important;
    text-align: center !important;
    color: white !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 1.5;    
}

.tip-badge {
  border-radius: 10px !important;
  /* box-shadow: 0 0 20px 20px rgba(19, 18, 18, 0.5);  */
}

.tip-max{
    border-radius: 10px !important;      
    color: white !important;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 1.5; 
    /* box-shadow: 0 0 20px 20px rgba(19, 18, 18, 0.5);  */
}



/* NS2-360 */
.Blink {
  animation: flasher 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@keyframes flasher {
  from { color: white; text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);}
  to { color: red; text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.7);} 
}

@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}
/* NS2-367 */
.no-pointer-events{
  pointer-events: none;
}
.disable-image{
  opacity: 0.5;
}

.font-awesome-grey{
color: grey !important;
}

.nameDiv{ 
  text-shadow: 2px 2px 2px black;
  z-index: 2;
}

.rec-button {
  z-index: 20;
  right: 7%;
  /* top: 8%; */
}

.rec {
  /* right: 5.5%; */
  /* top: 9%; */
}

/**TP-3213*/
#sidePanel {
  z-index: 1000; /*TP-4228*/
}
.sidePanel {
  max-height: 0px;
  -webkit-transition: max-height 2s ease-in 0s; /* For Safari 3.0+ */
  transition: max-height 0.2s ease-in 0s; /* Standard syntax */
}

.side-panel-card-shadow {
  box-shadow: 0 10px 10px rgba(255, 253, 253, 0.2);	
}

.side-panel-card {  
  border-radius: 10px !important;
}

.side-panel-card-logo {
  border-style: none !important;
  border-radius: 10px !important;
}

/*TP-3213*/
.nav-buttons-side-panel {
  position: absolute !important;
  top: 45%; 
  background-color: transparent;
  z-index:1060;
}

::-webkit-scrollbar {
  background: transparent; /* make scrollbar transparent */
  /* -webkit-appearance: none; */
  width: 0;
  height: 0;
  /* margin-top: 10px; */
}

.side-panel-container {
  display: flex;
  flex-direction: row;
  width: "100%";
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;  
}

.panel-background {
  background-color: #1d381c33 !important;
}

.grid-card {	
  box-shadow: 0 10px 10px rgba(255, 253, 253, 0.2);	
  border-radius: 10px !important;
}

.grid-card-logo {
  border-style: none !important;
  border-radius: 10px !important;
}

.paddingVdo {
  padding-left: 6px;  
  padding-top: 10px;
}

.marginVdo {
  margin-left: 6px;
  margin-top: 10px;
}

/* MB2-420 */
.video-stream{
  -moz-border-radius:10px;
  -webkit-border-radius:10px;
  border-radius:10px !important;
}

video.fit-cover {
  object-fit: cover !important;
}

video.fit-contain {
  object-fit: contain !important;
}

video.border {
  border-radius:10px !important;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; */
}

.annotate-class {
  background-color: #242323;
  top: 0 !important;
  opacity: 0.5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.annotate-webrtc {
  background-color: #242323;
  opacity: 0.5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.hide {
  display: none;
}

.hide:hover {
  transition: 100ms;
  display: block;
}

.hover-grid {
  height: 100%; 
  width: 100%;  
}

.hover-grid:hover {
  transition: 500ms;
  height: 103%; 
  width: 101%;
}

.hover-grid:hover + .hide {
  transition: 100ms;
  display: block;
}

.grid-cross-parent {
  /* position: absolute !important;
  left: 42% !important; */
  float: right !important;
  right: 80px !important;
  top: 6% !important; 
}

.web-grid-cross-parent {
  top: 7% !important;
  right: 10% !important;
}

.grid-cross-btn {
  position: absolute !important;
  color:white; 
  z-index: 2;
}

.grid-cross-btn:hover {  
  opacity:1.0; 
  color:lightblue;
  text-shadow: 2px 10px 10px #d4af37;
}

/*TP-2967 -- use case 4*/
@media screen and (min-height: 1000px) {
  .grid-cross-icon:hover {
    transition: 500ms;
    font-size: 25px;
  }
  .grid-icon:hover {
    transition: 500ms;
    font-size:  25px;
  }
} 

.grid-view-controls-parent {
  position: absolute !important;
  left: 0% !important;  
  bottom: 5% !important;
  z-index: 1050; /*TP-2342*/
}

.web-grid-loader-parent {
  color: white;
  bottom: 9% !important;
  z-index: 2;
}

.web-grid-view-controls-parent {
  bottom: 9% !important;
  /*z-index: 1050; 	TP-3937 & TP-2342*/
}

@media screen and (max-width: 450px) {
  .grid-left{
    margin-left: 60%;
  }
}

@media screen and (min-width: 451px) {
  .grid-left{
    margin-left: 60%;
  }
}

@media screen and (min-width: 1000px) {
  .grid-left{
    margin-left: 70%;
  }
}

@media screen and (min-width: 1010px) {
  .grid-left{
    margin-left: 60%;
  }
}

@media screen and (min-width: 1500px) {
  .grid-left{
    margin-left: 75%;
  }
}

@media screen and (min-width: 1900px) {
  .grid-left{
    margin-left: 80%;
  }
}


.grid-view-controls {
  z-index: 20;  
}

.grid-circle:hover {
  transition: 500ms !important;
  font-size:  90px !important;
  opacity:1.0; 
  color:lightblue;
}

.red-shadow {  
}

.grid-view-controls:hover {
  text-shadow: 2px 6px 6px #d4af37;
  /* transition: 500ms !important;
  font-size:  50px !important; */
 /*  opacity:1.0; 
  color:lightblue; */
}

.d-none {
  display: none;
}

.d-blk {
  display: blo;
}

.stop-rc {
  position: absolute !important;
  left: 48.5%;
  z-index: 99999;
  cursor: wait;
  border: none;
  margin: 0px;
  padding: 0px;
  /* width: 100% !important;
  height: 100% !important; */
  top: 10%;
  /* left: 2%; */
}

#stopRC {
  cursor: pointer;
}

.react-tooltip-clickable-link {
  pointer-events: auto !important;
  /* background-color: #4d4a49;
  color: white; */
}

.popover-header {
  text-align: center;
}

.hide {
  display: none;
}

.video-freeze {
  position: absolute !important;
  margin: 0 0;
  z-index: 3; /*TP-4287*/
  border: none;
}

/* .video-freeze:hover + .hide {
  display: block;
} */

.video-icon {
  position: relative;
  margin: 25% 0;
}

.fileBody h3 {
  text-align: right !important;
  text-shadow: 2px 2px 2px white;
}

.middle-row {
  /* height: 12%; */
}

#file-viewer > .modal-content {
  background: rgba(255,255,255,0);
  border: none;
}

.file-name {
  /* top: 7% !important; */
  text-align: center !important;
  font-size: 25px;
  z-index: 3;
  text-shadow: 2px 2px 2px black;
}

#request-dialog {
  z-index: 1070 !important;
  margin-top: 8% !important;
}

/*TP-2491*/
#canvas {
  font-family:FontAwesome;  
}

/*TP-4118 -- Handling of showing/hiding of default cursor in 
Canvas element when Pointer Annotation is either selected or
not selected */
.cursor-cross-hair {
  cursor: crosshair !important;
}

.cursor-none {
  cursor: none !important;
}

/*TP-2720 */
.upper-canvas {
  /*cursor: crosshair !important;*/ 
}

/*TP-2966 -- use case 2*/
.custom-modal-style {
  position: absolute !important;
  top: -47px;
  width: 897px;
  height: 250px;
  z-index: 1070 !important;
}
/*TP-3200*/
.chat-pic{
  border-radius: 50%;
  height: 35px;
  width: 35px;
  object-fit: cover;
  vertical-align: middle;
}
/*TP-3572	& TP-3201*/
.session-profile-pic {
  border-radius: 50% !important;
  object-fit: cover;
}

/*TP-3679 TP-3848
.session-profile-pic-position {
  /* margin-top: 45% !important; */
  /* margin-left: 38.5% !important; 
}*/

.logo-profile-pic {
  height: 50px;
  width: 50px;
  margin-right: 1.5%;
}

.logo-profile-pic-list {
  height: 30px;
  width: 30px;
  margin-right: 1.5%;
}

.profile-cursor {
  cursor: pointer;
}

.high-zIndex {
  z-index:9999 !important;
}

/*TP-4791*/
.zindex-1062 {
  z-index: 1062 !important;
}

/*TP-4572 -- Indicator on Expert/technician when Live video Paused*/
.paused-icon {
  position:absolute !important;
  margin-top: 20%;
  /* margin-left: 37%; TP-4589*/
  z-index: 900;
}

.paused-icon-grid {
  margin-top: 22%;
  margin-left: -55%;
}

.paused-text {
  color: black;
  background-color: #fff;
  font-size: 20px;
  position:absolute;
  margin-top: 38%; /*	TP-4900*/
  /* margin-left: 31%; TP-4589*/
  z-index: 900;
  padding: 0px 10px 3px 10px;
  border-radius: 5px;
  /* text-shadow: 2px 2px 2px black; */
}

/*TP-5551*/
.paused-text-other {
  color: black;
  background-color: #fff;
  font-size: 20px;
  position:absolute;
  /* margin-top: 39%;  *//*	TP-4900*/
  /* margin-left: 31%; TP-4589*/
  z-index: 900;
  padding: 0px 10px 3px 10px;
  border-radius: 5px;
  /* text-shadow: 2px 2px 2px black; */
}

.paused-text-grid {  
  font-size: 18px !important;  /*TP-4589*/ 
}

/*TP-5551*/
.muted-text {
  color: black;
  background-color: #fff;
  font-size: 20px;
  position:absolute;
  /* margin-top: 30%; */ /*TP-5673 TP-4900*/
  z-index: 900;
  padding: 0px 10px 3px 10px;
  border-radius: 5px;
}

.tile-border {
  /* border-style: solid;
  border-color: rgba(249, 208, 4, 0.809);
  border-width: 5px; */
  box-shadow: 0 10px 10px rgba(249, 208, 4, 0.809) !important;
}