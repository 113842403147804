.modal-centered {
    position: absolute;
    top: 30%;
    left: 37%;
    font-size: x-large;    
}

/* .modal-content {
    height: 300px;
}

.modal-body {
    margin-top: 10%;
} */

.log-me-center-dialog {
    top: 6% !important;    
      /* height: 630px; */
      border-radius: 25px;
      margin-top: 1%;
      /* margin-left: 20%;
      margin-right: 20%; */
      padding: 1% 2% 4% 1%;
  }

.log-me-color {
    background-color: transparent !important;    
  }
  
  .log-me-min-width {
    width: 700px;
  }