.user-units-container {
    .item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
    }
    .quantity input {
        -webkit-appearance: none;
        border: none;
        text-align: center;
        width: 60px;
        margin-left: 5px;
        font-size: 22px;
        color: #43484D;
        font-weight: 300;
    }

    .totals-item {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .plus-btn, .minus-btn {
        width: 30px;
        height: 30px;
        background-color: #E1E8EE;
        border-radius: 6px;
        border: none;
        cursor: pointer;
    }

    button:focus,
    input:focus {
        outline: 0;
    }

    .total-price {
        width: 83px;
        text-align: center;
        font-size: 22px;
        color: #43484D;
        font-weight: 300;
    }

    .description span {
        display: block;
        font-size: 22px;
        color: #43484D;
        font-weight: 400;
    }

    .h5-text {
        margin-top: 20px;
        margin-bottom: 0;
    }

    @media (max-width: 800px) {
        .item {
            height: auto;
            flex-wrap: wrap;
            justify-content: center;
        }

        .image,
        .quantity,
        .description {
            display: flex;
            align-items: center;
        }

        .buttons {
            margin-right: 20px;
        }
    }
}