
@import '../../styles/shared';

.btn-green-signup {
	@include button-mixin($height: 40px);
	text-transform: uppercase;
	text-decoration: none;
}
.btn-green:hover {
	opacity: 1;       
}

.signup-validation-message {
	@include validation-message();
}

.pricing-container {
	padding: 30px 0 10px 0;
}