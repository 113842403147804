@import '../../styles/shared';

.legend {
    padding: 0 10px 0 10px;
}
.account-info {
    background: #384571;
    align-self: center;
    width: 330px;
    padding: 10px;
    margin-bottom: 3px;
    h4 {
        margin: 0;
    }
    .summary-header {
        font-size: 18px;
        .fa {
            margin-left: 10px;
        }
    }
}

.trial-period-end-text {
    color: #dc3545;
}

.trial-badge {
    margin-left: 10px;
}

a {
    text-decoration: none;
    color: inherit;
}

.tbl-card-list {
    th, td {
        padding: 0.35rem !important;
    }
    th {
        font-size: 14px !important;
    }
    td {
        font-size: 12px;
        vertical-align: middle !important;
    }
}

.brand-icon {
    img {
        height: 20px;
        width: auto;    
    }
}

.ad-btn-green {
    @include button-mixin($height: 40px);
}

.modal-loader {
    width: inherit;
    cursor: wait;
    // background-color: rgba(108, 98, 98, 0.33) !important;
    position: absolute;
    top: 0;
    right: 44%;
}