.heading2 {
    color: #f5f6fa;
    margin-left: 30%;
    padding: 10px 0 0px 10px;
}
div .select-box {
    margin-left: 8%;
    display: flex;
    width: 400px;
    flex-direction: column;
    margin-top: 5%;
    border-color: black;
}
.select-box .options-container {
    background-color: #dfe4ec /* #384571 */;
    color: black;
    max-height: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.4s;
    border-radius: 8px;
    border-color: black;
    overflow: hidden;

    order: 1;
}

.selected {
    background-color: #dfe4ec;
    color: black;
    border-radius: 8px;
    margin-bottom: 8px;
    position: relative;
    order: 0;
}

.selected::after {
    content: "";
    background: url("/Arrow_Down_white.png");
    background-size: 80%;
    background-repeat: no-repeat;

    position: absolute;
    height: 100%;
    width: 32px;
    right: 10px;
    top: 10px;

    transition: all 0.4s;
}

.selected::after:hover {
    content: "";
    background: url("/Arrow_Down_Hover.png");
    background-size: 80%;
    background-repeat: no-repeat;
}

.select-box .options-container.active {
    max-height: 100px;
    opacity: 1;
    overflow-y: auto;
}

.select-box .options-container.active + .selected::after {
    /* transform: ; */
    background: url("/Arrow_Down_Click.png");
    background-size: 80%;
    background-repeat: no-repeat;
    /* transform: rotateX(180d);
    top: px; */
}

.select-box .options-container::-webkit-scrollbar {
    width: 8px;
    background: #0d141f;
    border-radius: 0 8px 8px 0;
}

.select-box .options-container::-webkit-scrollbar-thumb {
    background: #f5f6fa;
    border-radius: 0 8px 8px 0;
}
.select-box .option,.selected {
    padding: 12px;
    cursor: pointer;
}

.select-box .option:hover {
    background: #f5f6fa;
}

.select-box .label {
    cursor: pointer;
}

.select-box .option .selectRadio {
    display: none;
}

.background-modal{
  background-color: white/*  #414fcb */ /* rgb(144,82,218) */;
  /* border-radius: 8px; */
  overflow: hidden;
}

.closeBtnModal {
    background-color: #f5f6fa;
    border-radius: 100%;
}