@import '../../styles/shared';

#jobsTable > th {
  background-color: #7452cb;
  font-size: 21px;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

td .fa-file-pdf-o-read {
    color: #C0FF02;
  }

#job-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 40px;
  margin-left: auto;
}

.dropdown-content {
  position: absolute;
  min-width: 50px;
  color:black;
  background-color: aliceblue;
  z-index: 10;

  a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  a:hover {
    color:black !important;
    scale:0.9;
    transform: scale(0.9);
  }
}

/*TP-3220, TP-3222, TP-3223*/
.jobs-form-body {
  .input-wrapper {
      margin-bottom: 10px;
    }
  
    .margin-bottom-15 {
      margin-bottom: 15px;
    }
  .margin-top-15 {
    margin-top: 15px;
  }
  .text-black{
    color: black;
  }
}

.width-min {
  width: 800px;
}

.procedure-buttons-container {
  padding-left: 13px;
  .task-buttons{
    border-radius: 30%;
    border-color: $mentorLandingBackgroundColor;
    width: 40px;
    /* background-color: aqua; */
    text-emphasis: 100px;
  }
  .task-buttons:hover{
    background-color: $tabsHover !important;
  }
  .task-buttons:active{
    background-color: #5f3575 !important;
  }
}
//TP-3259 & TP-3260
.centering{
  display: flex;
  justify-content: center;
  align-items: center;
}

//TP-3490
.job-button{
  font-size: 18px;
}

.btn-green-medium {
  @include button-mixin(
    $background: $dark-blue,
    $height: 30px,
    $padding: 0 15px 0 15px,
    $border-radius: 15px
  );
}

.btn-green-medium:hover {
  @include button-mixin(
    $background: $dark-blue,
    $height: 30px,
    $padding: 0 15px 0 15px,
    $border-radius: 15px,
  );
}

.bg-selected{
  background-color: #2d3858;
}

.position-sticky{
  position: sticky;
}

//TZ-372
a.white:hover{
    color: white;
}
