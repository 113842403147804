.actions-wrapper {
    width: 100%;
    padding: 30px;
    bottom: 0;
}
.fa-sendchat-circle {
    background-color: #384571 !important;
    color: #384571 !important;
    border-radius: 50%;
    font-size: 32px;
}

.fa-maxdiv-circle {
    background-color: white;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    font-size: 30px;
}

.fa-maxdiv-action {
    color: black;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    font-size: 20px;
}

.fa-maxdiv-action:hover {
    background-color: lightblue;
    border-radius: 50%;
}

.fa-maxdiv-action:focus {
    background-color: red;
    border-radius: 50%;
}
