@import '../../styles/shared';

.__modal-body {
  color: $default-blue;

  .input-wrapper {
    margin-bottom: 10px;
  }

  .margin-bottom-15 {
    margin-bottom: 15px;
  }
}

.__modal-header {
  color: $default-blue;
}

.add-user-modal {
  .__modal-dialog {
    top: 5%;
  }
}

.width-min {
  width: 600px;
}

.formheader {
  h2 {
    text-align: center;
    padding: 1% 2% 0% 1%;
  }
  h4 {
    text-align: center;
    padding: 1% 2% 0% 1%;
  }
  label {
    text-align: center;
    padding: 1% 2% 0% 1%;
  }
  img {
    margin-left: 5%;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formbody {
  top: 6% !important;
  .input-wrapper {
    margin-bottom: 10px;
    margin-left: 15px;
  }
  .column {
    float: left;
    width: 50%;
    padding: 1% 1% 1% 1%;
  }
  .topSpace {
    top: 20% !important;
  }

  label {
    margin-left: 15px;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
    margin-left: 10px;
  }
}
/*TP-3220*/
  .form-color {
    background-color: $mentorLandingBackgroundColor !important;    
  }

  .form-text-color {
    color: $tabsColor !important;
  }

  .dialog {
    top: 6% !important;    
    height: 630px;
    border-radius: 25px;
    margin-top: 1%;
    margin-left: 20%;
    margin-right: 20%;
    padding: 1% 2% 4% 1%;    
  }

  .signup-dialog{
    @extend .dialog;
    background: transparent;
  }
  
  .margin10{
    margin-left: 50px;
  }

.labelField {
  padding-left: 6px;
}

.btn-red {
  @include button-mixin($background: $default-red);  
}

.btn-green {
  @include button-mixin();
}

.btn-green:hover, .btn-red:hover {
  @include button-mixin($background: $tabsHover);
}

.btn-blue {
  @include button-mixin($background: $dark-blue);
}

.btn-grey {
  @include button-mixin($background: $disabledFontColor);
}

.groups-form-validation-msg {
  @include validation-message($margin-top: 0px, $font-size: 12px, $right: 0px, $left: none)
}

.job-form-validation-msg {
  @include validation-message($margin-top: 0px, $font-size: 12px, $right: 15px, $left: none)
}

.__modal-header-add {
  padding: 2px 16px;
  background-color: #7452cb;
  color: white;
}

.search-input-wrapper {
  i {
    position: absolute;
    top: 7px;
    left: 10px;
    font-size: 18px;
    color: #ddd;
    z-index: 0;
  }
  input {
    background-color: #384571;
    width: 100%;
    font-size: 18px;
    padding: 5px 10px 5px 10px;
    margin-bottom: 2px;
    color: white;
    border-radius: 3px;
    padding: 5px 5px 7px 35px;
    border: none;
  }
}

.btn-green-add {
    position: relative;
    outline: none;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

th {
  background-color: #7452cb;
  font-size: 21px;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}
/*TP-3259*/
#groupsTable th,
#groupsTable td,
#usersTable th,
#usersTable td,
#jobsTable th,
#jobsTable td,
#assJobsTable th,
#assJobsTable td,
#assAssetsTable th,
#assAssetsTable td {
  text-align: left;
  // padding: 8.5px 6px;
  padding: 1px 6px 1px 6px;
  position: relative;
}

#usageTable th,
#usageTable td,
#missedCallTable th,
#missedCallTable td {
  text-align: left;
  // padding: 8.5px 6px;
  padding: 2px 6px 2px 6px;
  position: relative;
}

td.actions {
  display: flex;
  justify-content: space-around;
}

/**TZ-475*/
.first-child-missed-call {
  background-color: #cbd4f5 !important;
  color: black !important;
}

tr:nth-child(odd) {
  background-color: #384571;
  border-radius: 3px;
}

tr:nth-child(even) {
  background-color: #2d3858;
  border-radius: 3px;
}

// TP-6629
.trans-table {
  tr:nth-child(odd) {
    background-color: #FFFFFF;
    border-bottom: 3px solid #000000;
  }
  
  tr:nth-child(even) {
    background-color: #F2F2F2;
    border-bottom: 3px solid #000000;
  }
  
}

.icon-wrapper {
  @extend .hvr-grow;
  // margin-right: 10px;
  font-size: 19px;

  .fa-video {
    color: $default-green;
  }

  .fa-video-disabled {
    color: gray!important;
  }

  .fa-trash-alt {
    color: #cd4d4d;
  }

  :hover {
    transform: scale(1.1);
  }
}

.add-users-validation-msg {
  @include validation-message($margin-top: 0px, $font-size: 12px, $right: 0px, $left: none)
}

//TP-6147
.profile-validation-msg {
  @include validation-message($margin-top: 0px, $font-size: 12px, $right: auto, $left: none)
}

.user-type-flag {
  padding: 0px 8px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: bold;
}

.Invitee {
  background: #777;
}

.Technician {
  background: #71a95a;
}

.Expert {
  background: $default-blue;
}

.Inactive {
  background: #c94e4a;
}

.Admin {
  background: #89783f;
}

.admin-expert {
  background: #89783f;
}

.pagination-wrapper {
  // position: fixed;
  // bottom: 35px;
  margin-top: 4px;
  right: 20px !important;
  left: inherit !important;
  margin-bottom: 5% !important;
  max-height: 15px !important; /*TP-2883*/
  .pagination {
    margin-bottom: 0 !important;
  }
}
// .table-container {
//   /* TP-2883 min-height: 290px; */
// }

tr.no-sort{
  th{
    cursor: default;
  }
}

.align-center {
  margin: 0 auto;
}
.margin-b-10 {
  margin-bottom: 10px;
}
.custom-date{
  margin-top: 1px !important;
}

.stream-mode-desc {
  padding: 5px;
  background: $default-blue;
  border-radius: 5px;
}

.call-notification-toast {
  margin: 0 auto;
  .call-notification-toast-body {
    background: #fff;
    color: #6c757d;
  }
}
.red-trash{
  color: red;
}
.bg-created{
  background-color: #d19a52 !important;
}
.bg-assigned{
  background-color: #bfe8f2 !important;
}
.bg-in-progress{
  background-color: #eaf099 !important;
}
.bg-finished{
  background-color: #defde0 !important;
}
.bg-white{
  background-color: #ffffff !important;
}
/*TP-3220, TP-3222, TP-3223 -- new color background for New Task created*/
.bg-new-task{
  background-color: #a432d1 !important;
}

#Download {
  .fa-file-csv, .fa-long-arrow-alt-down {
    color: #fff;
  }
}

.recording-url, a.recording-url:hover, a.recording-url:focus, a.recording-url:active {
  /* color: #fff; */
  cursor: pointer;
}

/*TP-2892 -- CSS for Mobile specific class*/
.group-dropdown {
  min-height: 40px;
  width: 100%;
  padding: 0 5px 0 10px;
  border-radius: 5px;
  border-color: #999da1 ;
  color: gray;
}

.group-option {
  height: 30px !important;
  font-size: 150% !important;
  color: gray;
  border-radius: 5px;
}

.hidden-optn {
  display: none;
}
/*TP-3259*/
.show-ellipses {
  line-height: 1em;
  height: 2em;       /* height is 2x line-height, so two lines will display */
  overflow: hidden;  /* prevents extra lines from being visible */
}

.width-header {
  padding:1% 15% 0% 15% !important;
}

.bright-green-font{
  color: #39FF14 !important;
}

.grey-font{
  color: #a1a1a1 !important;
}

//TP-3459
.profile-header {
  color: #7452cb !important;
}

/*TP-4871*/
.center-align {
  text-align: center !important;
}

.max-width-profile {
  max-width: 45px !important;  
}

.min-width-profile {
  min-width: 56px !important;
}
/*TP-6222*/
.chat-page-container {
  width: 100px;
  left: 50%;
}

.chat-parent-container {
  margin-top: 3px;
}