@import '../../styles/variables';

#tooltip, #invite_slider
{
  position: absolute;
  left: 50px; top: 50px;
  /* font-family: Helvetica Neue,Helvetica,Arial,sans-serif; */
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  display: none;
  z-index: 9999;
  min-width: 280px;
}

#tooltip.bottom, #invite_slider.bottom
{
  margin-top: 0;
}

#tooltip .tooltip-arrow, #invite_slider .tooltip-arrow
{
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: rgb(156, 151, 151);
}


#tooltip.bottom .tooltip-arrow, #invite_slider.bottom .tooltip-arrow
{
  top: 0;
  left: 2%;
  /* margin-left: -5px; */
  border-width: 0 5px 5px;
  border-bottom-color: rgb(156, 151, 151);
}

#tooltip .tooltip-arrow, #invite_slider .tooltip-arrow
{
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltip .tooltip-inner, #invite_slider .tooltip-inner
{
  min-width: 280px;
  padding: 3px 8px;
  color: white;  
  background-color: rgb(156, 151, 151);
  border-radius: 4px;
}

#invite_slider a, #tooltip a{
  color: white;
}

#tooltip .container-body, #invite_slider .container-body
{
    text-align: left;
    text-align: start;
    font-size: 15px;
}

#tooltip.on, #invite_slider.on
{
  display: block;
}

#tooltip.off, #invite_slider.off
{
  display: none;
}

.tooltip-title-class{
  margin-bottom: 5px;
  line-height:2.2;
  font-weight: 600;
  text-align: center;
}

.tooltip-body-cant{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 3px solid green; 
}

.fa_custom {
  color: #248C46;
  margin: 10px;
}

.fa-asterisk {
  color: white;
}

.icon-disabled {
  color: $disablePinkGrey;
}