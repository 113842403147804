/* Modal styles **/

.btn-close {
    color: #aaaaaa;
    font-size: 20px;
    text-decoration: none;
    padding:10px;
    position: absolute;
    right: 7px;
    top: 0;
    cursor: pointer;
  }
  .btn-close:hover {
    color: #919191;
  }
  .modale:before {
    content: "";
    display: none;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  .opened:before {
    display: block;
  }
  .opened .__modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    top: 10%;
  }
  .__modal-dialog {
    background: #fefefe;
    border: #333333 solid 0px;
    border-radius: 25px;
    margin-left: -200px;
    text-align: left;
    position: fixed;
    left: 50%;
    top: -100%;
    z-index: 11;
    width: 420px;
    box-shadow:0 5px 10px rgba(0,0,0,0.3);
    -webkit-transform: translate(0, -500%);
    -ms-transform: translate(0, -500%);
    transform: translate(0, -500%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    @media (min-width: 320px) and (max-width: 480px)  {
        height: auto;
        margin: 5% auto;
        max-height: 57em;
        max-width: 66em;
        width: 100%;
        left: 0;
    }
  }
  .__modal-body {
    padding: 20px;
  }
  .__modal-header,
  .__modal-footer {
    padding: 10px 20px;
    text-align: center;
  }
  .__modal-header {
    border-bottom: #eeeeee solid 1px;
  }
  .__modal-header h2 {
    font-size: 20px;
  }